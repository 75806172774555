import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfigsAsync } from "../../slices/ConfigSlice";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../slices/ToastSlice";
import EditIcon from "@mui/icons-material/Edit";
import { updateConfigsImpacts } from "../../slices/api/configsApi";

const SLAs = () => {
  const dispatch = useDispatch();
  const configsState = useSelector((state) => state.configs);
  const [isEditingIndex, setIsEditingIndex] = useState(null);
  const [tempHours, setTempHours] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchConfigsAsync(dispatch);
  }, [dispatch]);

  const handleHoursChange = (newValue, index) => {
    setTempHours(newValue);
    setIsEditingIndex(index);
  };

  const updateSlaHours = (value, updatedHours, costImpacts) => {
    return costImpacts.map((impact) => {
      if (impact.value === value) {
        return { ...impact, slaInHours: parseInt(updatedHours) };
      }
      return impact;
    });
  };

  const handleUpdate = async (impactValue) => {
    setIsSubmitting(true);

    try {
      let updatedCostImpacts = updateSlaHours(
        impactValue,
        tempHours,
        configsState.impacts
      );

      await updateConfigsImpacts(updatedCostImpacts);
      dispatch(setToastMessage("Successfully update the SLA hours."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } catch (error) {
      dispatch(setToastMessage("Failed to update SLA hours."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } finally {
      setIsEditingIndex(null);
      setIsSubmitting(false);
    }
  };

  const handleShowEdit = (index) => {
    setTempHours(configsState.impacts[index].slaInHours);
    setIsEditingIndex(index);
  };

  return (
    <div id="slas">
      <div className="header-part">
        <h2>Service Level Agreements</h2>
      </div>
      <br></br>
      <div className="sub-cate">
        <ul className="list-unstyled shadow">
          {configsState && configsState.impacts ? (
            configsState.impacts.map((impact, index) => (
              <li key={impact.id}>
                <span className="categ-name">
                  {impact.label} - Time:{" "}
                  {isEditingIndex === index ? (
                    <input
                      type="number"
                      value={tempHours !== null ? tempHours : impact.slaInHours}
                      min={1}
                      onChange={(e) => handleHoursChange(e.target.value, index)}
                    />
                  ) : (
                    <span>{impact.slaInHours} hours</span>
                  )}
                </span>
                <div className="sub-action-btns">
                  {isEditingIndex === index ? (
                    <>
                      <button
                        type="button"
                        className="main-btn"
                        onClick={() => handleUpdate(impact.value)}
                        disabled={
                          isSubmitting ||
                          tempHours === null ||
                          impact.slaInHours === tempHours
                        }
                      >
                        Update
                      </button>
                      <button
                        className="no-border-btn"
                        onClick={() => {
                          setIsEditingIndex(null);
                          setTempHours(null);
                        }}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="edit-btn"
                      onClick={() => handleShowEdit(index)}
                      disabled={isSubmitting}
                    >
                      <EditIcon fontSize="small" />
                    </button>
                  )}
                </div>
              </li>
            ))
          ) : (
            <li>No SLA configs available</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SLAs;
