import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import moment from "moment";
import SingleCaseTabs from "./SingleCaseViewTabs";
import ConversationTab from "./single-case-tabs/ConversationTab";
import DetailsTab from "./single-case-tabs/DetailsTab";
import ResolutionTab from "./single-case-tabs/ResolutionTab";
import ReplyIcon from "@mui/icons-material/Reply";
import AttachmentsTab from "./single-case-tabs/AttachmentsTab";
import {
  subscribeToCases,
  subscribeToSingleCase,
  escalateDepartment as escalateDepartmentApi,
  updateCaseMasterData as updateCaseMasterDataApi,
} from "../../slices/api/casesApi";
import { getAllUsers } from "../../slices/api/usersApi";

import NameResolver from "../common/NameResolver";
import Constants from "../../constants/Constants";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../slices/ToastSlice";
import { sendEmailApi } from "../../services/commonService";
import { subscribeToDepartments } from "../../slices/api/departmentsApi";
import { formatStatus } from "../../utils";

const SingleCaseView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("activeTab") || "conversation";

  const activeCase = useSelector((state) => state.cases.activeCase);
  const authUser = useSelector((state) => state.auth);
  const departmentsState = useSelector((state) => state.departments);
  const usersState = useSelector((state) => state.user);

  const [departments, setDepartments] = useState([]);
  const [selectedDeparment, setSelectedDepartment] = useState();
  const [escalationReason, setEscalationReason] = useState("");
  const [showEscalateCase, setShowEscalateCase] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAssignCase, setShowAssignCase] = useState(false);

  useEffect(() => {
    if (departmentsState && departmentsState.departmentsList) {
      const filteredDepartments = departmentsState.departmentsList.filter(
        (category) =>
          category.parentCategory == "main" &&
          category.id != activeCase.department
      );
      setDepartments(filteredDepartments);
    }
  }, [usersState, departmentsState]);

  useEffect(() => {
    let unsubscribe = false;
    const fullUrl = window.location.href;
    const match = fullUrl.match(/single_case\/([^/]+)/);

    if (authUser !== null && authUser !== undefined) {
      const isSuperAdmin = authUser.user.userInfo.isSuperAdmin ? true : false;
      unsubscribe = subscribeToCases(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );

      subscribeToDepartments(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );

      getAllUsers(dispatch, authUser.user.userInfo.department, isSuperAdmin);

      if (match && !activeCase) {
        const id = match[1];
        subscribeToSingleCase(dispatch, id);
      } else {
        console.log("Id not found in the URL.");
      }
    }

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [dispatch, authUser]);

  const handleTabChange = (tab) => {
    navigate(`?activeTab=${tab}`);
  };

  const handleCloseCase = () => {
    navigate(`?activeTab=resolution`);
  };

  const checkIfAssigneeOrAdmin = () => {
    const isAdmin =
      authUser.user.userInfo.isAdmin || authUser.user.userInfo.isSuperAdmin;

    if (activeCase && activeCase.assignedTo) {
      const isAssignee = activeCase.assignedTo.find(
        (assignee) => assignee == authUser.user.userInfo.mail
      );

      return isAdmin || !!isAssignee;
    } else return false;
  };

  const handleNavigateToComment = () => {
    if (!checkIfAssigneeOrAdmin()) {
      dispatch(
        setToastMessage(
          "You not assigned this case, and won't be able to comment."
        )
      );
      dispatch(setToastTimer(4000));
      dispatch(setShowToast(true));
    }

    navigate(`?activeTab=conversation#new_comment`);

    setTimeout(() => {
      const bottomElement = document.getElementById("new_comment");
      if (bottomElement) {
        bottomElement.scrollTo({
          top: bottomElement.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 170);
  };

  const handleEscalate = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const adminsEmailsObj = selectedDeparment.admins;
    const adminsEmailsArray = Object.keys(adminsEmailsObj);
    const adminsEmailsString = adminsEmailsArray.toString();

    const escalationData = {
      department: selectedDeparment.id,
      escalationReason: escalationReason,
      departmentAdminEmail: adminsEmailsString,
      requester: activeCase.caseRequester,
      escalationDateTime: new Date().toString(),
      currentDepartment: activeCase.department,
      currentAsignee: activeCase.assignedTo,
    };
    const caseId = activeCase.id;

    try {
      await escalateDepartmentApi(caseId, escalationData);
      let templateData = {
        departmentName: getDepartmentNameById(activeCase.department),
        escalationReason: escalationReason,
        caseNumber: activeCase.caseNumber,
        caseSummary: activeCase.caseSummary,
        impactType: activeCase.impact,
        caseLink: `${window.location.protocol}://${window.location.hostname}/cases/${caseId}`,
      };

      const emailPayload = {
        recipientEmailAddresses: [adminsEmailsString],
        templateData: templateData,
        sourceEmail: Constants.sourceEmailList.NO_REPLY,
        templateName: "case_escalated_admin_and_helpdesk_officer_v1",
      };

      if (emailPayload.recipientEmailAddresses.length > 0) {
        try {
          await sendEmailApi(emailPayload);
        } catch (e) {}
      }

      dispatch(
        setToastMessage(`Successfully escalated the case to department.`)
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
      setTimeout(() => {
        setIsSubmitting(false);
        navigate("/cases");
      }, 3000);
    } catch (error) {
      console.error(error);
      dispatch(
        setToastMessage(
          `Error occured while trying to escalate the case to department..`
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
      setIsSubmitting(false);
    }
  };

  const handleDepartmentChange = (event, selected) => {
    setSelectedDepartment(selected);
  };

  const handleAssignCase = async (event) => {
    event.stopPropagation();
    setIsSubmitting(true);

    const newAssigneesMails = selectedUsers.map((user) => user.mail);
    const newAssigneesToNotify = newAssigneesMails.filter(
      (email) => !activeCase.assignedTo.includes(email)
    );

    try {
      let payload = {
        dateUpdated: new Date().toString(),
        assignedTo: newAssigneesMails,
      };

      await updateCaseMasterDataApi(activeCase.id, payload);

      try {
        let templateData = {
          assigneeName: "User",
          caseNumber: activeCase.caseNumber,
          caseSummary: activeCase.caseSummary,
          impactType: activeCase.impact,
          caseLink: `${window.location.protocol}://${window.location.hostname}/cases/${activeCase.id}`,
        };

        const emailPayload = {
          recipientEmailAddresses: newAssigneesToNotify,
          templateData: templateData,
          sourceEmail: Constants.sourceEmailList.NO_REPLY,
          templateName: "user_assigned_on_case_v1",
        };

        if (emailPayload.recipientEmailAddresses.length > 0) {
          try {
            await sendEmailApi(emailPayload);
          } catch (e) {}
        }
      } catch (e) {}

      setShowAssignCase(false);
      dispatch(setToastMessage("Successfully updated the assignees."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
      setIsSubmitting(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setToastMessage(
          "An error occured while trying to update the assignees."
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  const handleShowEscalation = () => {
    setShowEscalateCase(!showEscalateCase);
  };

  const handleReOpenCase = async (event) => {
    event.stopPropagation();
    const answer = window.confirm("Are you sure you want to reopen this case?");
    if (answer) {
      try {
        let payload = {
          status: Constants.caseStatuses.OPEN,
          dateUpdated: new Date().toString(),
          reOpenedBy: authUser.user.userInfo.mail,
        };

        await updateCaseMasterDataApi(activeCase.id, payload);
        dispatch(setToastMessage("Successfully re-opened the case."));
        dispatch(setToastTimer(3000));
        dispatch(setShowToast(true));
      } catch (e) {
        console.log(e);
        dispatch(
          setToastMessage("An error occured while trying to re-open the case.")
        );
        dispatch(setToastTimer(3000));
        dispatch(setShowToast(true));
      }
    }
  };

  const getDepartmentNameById = () => {
    if (departmentsState && departmentsState.departmentsList) {
      const filteredDepartments = departmentsState.departmentsList.find(
        (category) =>
          category.parentCategory == "main" &&
          category.id == activeCase.department
      );
      return filteredDepartments.name;
    }

    return "Loading..";
  };

  const handleShowAssignCase = () => {
    setShowAssignCase(!showAssignCase);
  };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const getUserDetails = (usersList, assignedEmails) => {
    return usersList.filter((user) => assignedEmails.includes(user.mail));
  };

  useEffect(() => {
    if (usersState && usersState.users && activeCase && activeCase.assignedTo) {
      const assignedUsers = getUserDetails(
        usersState.users,
        activeCase.assignedTo
      );
      setSelectedUsers(assignedUsers);
    }
  }, [usersState, activeCase]);

  const handleChange = (event, value) => {
    setSelectedUsers(value);
  };

  return (
    <div id="single_case_view">
      {activeCase && Object.keys(activeCase).length > 0 ? (
        <>
          <div className="header-part">
            <div className="action-btns">
              <button
                type="button"
                className="back-btn"
                onClick={() => navigate("/cases")}
              >
                <ChevronLeftIcon /> Back
              </button>
              <div className="more-btns">
                {checkIfAssigneeOrAdmin() &&
                  activeCase.status != Constants.caseStatuses.CLOSED && (
                    <div className="assign-btn-container">
                      <>
                        <button
                          type="button"
                          className="case-btns"
                          onClick={handleShowAssignCase}
                        >
                          Assign
                        </button>
                        {showAssignCase && (
                          <div className="assign-case-popup shadow">
                            <h4>Assign Case</h4>
                            <div className="inputs-container">
                              <label htmlFor="" className="required-field">
                                Assignee
                              </label>
                              <Autocomplete
                                multiple
                                limitTags={5}
                                onChange={handleChange}
                                options={usersState.users.filter(
                                  (user) =>
                                    user.department === activeCase.department
                                )}
                                getOptionLabel={(user) =>
                                  `${user.givenName} ${user.surname}`
                                }
                                getOptionSelected={(option, value) =>
                                  option.mail === value.mail
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="-- Select Assignees --"
                                    variant="outlined"
                                  />
                                )}
                                value={selectedUsers}
                                required
                              />
                              <div className="btn-container">
                                <button
                                  type="button"
                                  className="main-btn"
                                  onClick={handleAssignCase}
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting ? "Assigning" : "Assign"}
                                </button>
                                <button
                                  type="button"
                                  className="no-border-btn"
                                  onClick={() =>
                                    setShowAssignCase(!showAssignCase)
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  )}
                {checkIfAssigneeOrAdmin() &&
                  activeCase.status != Constants.caseStatuses.CLOSED && (
                    <div className="escalate-btn-container">
                      <button
                        type="button"
                        className="case-btns"
                        onClick={handleShowEscalation}
                      >
                        Escalate
                      </button>
                      {showEscalateCase && (
                        <div className="escalate-case-form shadow">
                          <h4>Escalate to department</h4>
                          <p className="alert alert-info">
                            If this case does not belong to{" "}
                            <b>{getDepartmentNameById()}</b> department,
                            escalate it to the right department. Provide details
                            below.
                          </p>
                          <div className="inputs-container mb-3">
                            <label htmlFor="" className="required-field">
                              Department
                            </label>
                            <Autocomplete
                              value={selectedDeparment}
                              onChange={handleDepartmentChange}
                              options={departments}
                              getOptionLabel={(department) => department.name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="-- Select Category --"
                                  variant="outlined"
                                  required
                                />
                              )}
                            />
                          </div>
                          <div className="inputs-container">
                            <label htmlFor="" className="required-field">
                              Escalation Reason
                            </label>
                            <input
                              value={escalationReason}
                              onChange={(e) =>
                                setEscalationReason(e.target.value)
                              }
                              variant="outlined"
                              className="escalation-reason"
                              placeholder="Enter Escalation Reason"
                              required
                            />
                          </div>
                          <div className="btn-container">
                            <button
                              type="button"
                              className="main-btn"
                              onClick={handleEscalate}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Escalating..." : "Escalate"}
                            </button>
                            <button
                              type="button"
                              className="no-border-btn"
                              onClick={() =>
                                setShowEscalateCase(!showEscalateCase)
                              }
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                {checkIfAssigneeOrAdmin() &&
                  activeCase.status == Constants.caseStatuses.CLOSED && (
                    <button
                      type="button"
                      className="case-btns"
                      onClick={handleReOpenCase}
                    >
                      Re-open
                    </button>
                  )}
                {activeCase.status == Constants.caseStatuses.OPEN &&
                  checkIfAssigneeOrAdmin() && (
                    <button
                      type="button"
                      className="case-btns"
                      onClick={handleCloseCase}
                    >
                      Close
                    </button>
                  )}
              </div>
            </div>
          </div>
          <div className="single-case">
            {activeCase ? (
              <>
                <div className="case-header">
                  <h4>{activeCase.caseSummary}</h4>
                  <div className="header-footer">
                    <p>
                      Status:{" "}
                      {activeCase.status != Constants.caseStatuses.CLOSED &&
                      activeCase.assignedTo &&
                      activeCase.assignedTo.length > 0
                        ? formatStatus("inprogress")
                        : formatStatus(activeCase.status)}{" "}
                      <span className="v-separator"></span>
                      Priority: {activeCase.priority}
                      <span className="v-separator"></span> Requested by&nbsp;
                      <span className="global-name">
                        <NameResolver email={activeCase.caseRequester} />
                      </span>
                      &nbsp;on{" "}
                      {moment
                        .utc(
                          new Date(activeCase.dateCreated),
                          "MM/DD/YYYY h:mm A"
                        )
                        .format("MMM DD, YYYY HH:mm")}
                    </p>
                    <button
                      type="button"
                      className="reply-button"
                      onClick={handleNavigateToComment}
                    >
                      <ReplyIcon fontSize="small" /> Comment
                    </button>
                  </div>
                </div>
                <div className="tabs-container">
                  <SingleCaseTabs
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                  />
                </div>
                <div className="content-container">
                  {activeTab === "conversation" && <ConversationTab />}
                  {activeTab === "details" && <DetailsTab />}
                  {activeTab === "resolution" && <ResolutionTab />}
                  {activeTab === "attachments" && <AttachmentsTab />}
                </div>{" "}
              </>
            ) : (
              <>Loading...</>
            )}
          </div>
        </>
      ) : (
        <>Please wait...</>
      )}
    </div>
  );
};

export default SingleCaseView;
