import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { logOut } from "../actions/auth/authActions";
import LogoutIcon from "@mui/icons-material/Logout";
import { logoutAsync } from "../slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/firebase";
import { doc, getDoc } from "@firebase/firestore";
import { globalActions } from "../slices/globalSlice";

const TopNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.user);
  const [roleTitle, setUserRole] = useState("");

  useEffect(() => {
    // const getUserRole = async () => {
    //   const userRoleID = JSON.parse(localStorage.getItem("user")).role;
    //   const rolesRef = doc(db, "roles", userRoleID);
    //   const rolesInfo = await getDoc(rolesRef);
    //   const roleData = rolesInfo.data();
    //   setUserRole(roleData.title);
    // };
    // getUserRole();
  }, []);

  const handleLogOut = async () => {
    await dispatch(logoutAsync());
    navigate("/");
  };

  const handleOpenUserDialog = () => {
    dispatch(globalActions.toggleUserDialog());
  };

  return (
    <div>
      <div className="top-nav">
        <h4>Welcome Back, {userInfo.user.displayName}</h4>
        <div className="menu-list">
          {roleTitle === "Admin" && (
            <>
              <button type="button" className="main-btn">
                Add Department
              </button>
              <button
                onClick={handleOpenUserDialog}
                type="button"
                className="main-btn"
              >
                Add User
              </button>
              <span className="v-divider"></span>
            </>
          )}
          <button type="button" onClick={handleLogOut} className="logout-btn">
            <LogoutIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
