import React, { useState, useEffect } from "react";
import {
  addFAQToFirestore,
  deleteFAQFromFirestore,
  updateFAQInFirestore,
  subscribeToAllFAQs,
} from "../../slices/api/faqApi";
import { useDispatch, useSelector } from "react-redux";
import FAQsForm from "./faqs/FAQsForm";
import FAQsList from "./faqs/FAQsList";
import DeleteDialog from "../DeleteDialog";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../slices/ToastSlice";

const FAQs = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [faqId, setFaqId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [confirmDeleteShow, setConfirmDeleteShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const { faqsList } = useSelector((state) => state.faqs);
  const authUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    subscribeToAllFAQs(
      dispatch,
      authUser.user.userInfo.isAdmin,
      authUser.user.userInfo.department
    );
  }, [dispatch, authUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = new Date().toString();

    try {
      if (editMode) {
        await updateFAQInFirestore(faqId, question, answer);
      } else {
        const payload = {
          question,
          answer,
          dateCreated: currentDate,
          dateUpdated: currentDate,
          department: authUser.user.userInfo.department,
          createdByEmail: authUser.user.userInfo.mail,
        };
        await addFAQToFirestore(payload);
      }
      setQuestion("");
      setAnswer("");
      setEditMode(false);
      setFaqId("");
      setShowForm(false);
    } catch (error) {
      console.error("Error adding/updating FAQ:", error);
    }
  };

  const handleEdit = (faqId) => {
    const selectedFaq = faqsList.find((faq) => faq.id === faqId);
    if (selectedFaq) {
      setQuestion(selectedFaq.question);
      setAnswer(selectedFaq.answer);
      setFaqId(faqId);
      setEditMode(true);
      setShowForm(true);
    }
  };

  const handleDeleteClick = (id) => {
    setConfirmDeleteShow(true);
    setDeleteItemId(id);
  };

  const onHandleDelete = async () => {
    try {
      setConfirmDeleteShow(false);
      await deleteFAQFromFirestore(deleteItemId);
      dispatch(setToastMessage("Successfully removed the FAQ."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      setDeleteItemId(null);
      console.error(error);
      dispatch(
        setToastMessage("An error occurred while trying to remove the FAQ.")
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  const handleToggleForm = () => {
    setQuestion("");
    setAnswer("");
    setEditMode(false);
    setFaqId("");
    setShowForm(!showForm);
  };

  return (
    <div id="faqs">
      {!showForm && (
        <div className="header-part">
          <h2>Manage FAQs</h2>
          <button type="button" className="main-btn" onClick={handleToggleForm}>
            Add New FAQ
          </button>
        </div>
      )}

      <FAQsForm
        showForm={showForm}
        editMode={editMode}
        question={question}
        answer={answer}
        handleSubmit={handleSubmit}
        handleToggleForm={handleToggleForm}
        setQuestion={setQuestion}
        setAnswer={setAnswer}
      />

      {!showForm && (
        <FAQsList
          faqsList={faqsList}
          handleEdit={handleEdit}
          handleDeleteClick={handleDeleteClick}
        />
      )}

      {confirmDeleteShow && (
        <DeleteDialog
          message="Do you really want to delete this FAQ? This process cannot be undone."
          onDelete={onHandleDelete}
          onCancel={() => setConfirmDeleteShow(false)}
        />
      )}
    </div>
  );
};

export default FAQs;
