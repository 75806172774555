import { createSlice } from "@reduxjs/toolkit";
import CategoryService from "../services/categoriesService";

const categoryService = new CategoryService();

const initialState = {
  categories: [],
  category: {},
};

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const { setCategories } = categorySlice.actions;

export const fetchCategoriesAsync = async (dispatch, userId) => {
  let categories = await categoryService.readCategoriesWhereUserIsAdmin(userId);

  if (categories.length > 0) {
    categories = categories.map((category) => category.title);
  }
  dispatch(setCategories(categories));
};

export default categorySlice;
