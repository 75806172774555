import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSubcategory } from "../../../slices/api/departmentsApi";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../../slices/ToastSlice";

const AddCategory = ({ selectedCategory, handleShowAddCateg }) => {
  const [fullName, setFullName] = useState();
  const [shortName, setShortName] = useState();
  const authUser = useSelector((state) => state.auth);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setShortName("");
    setFullName("");
    handleShowAddCateg(false);
  };

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDisableSubmit(true);

    if (!fullName || !shortName) {
      dispatch(setToastMessage("Both full name and short name are required."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
      return;
    }

    const payload = {
      createdBy: authUser.user.userInfo.mail,
      dateCreated: new Date().toString(),
      dateUpdated: new Date().toString(),
      parentCategory: selectedCategory.id,
      rootParentCategory: selectedCategory.id,
      name: fullName,
      shortName,
    };

    try {
      await addSubcategory(payload);
      handleShowAddCateg(false);
      dispatch(setToastMessage("Successfully created the sub category."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
      setShortName("");
      setFullName("");
      setDisableSubmit(false);
    } catch (e) {
      setDisableSubmit(false);
      console.log(e);
      dispatch(
        setToastMessage(
          "An error occurred while trying to create the sub category."
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  return (
    <div className="add-categ-form shadow">
      <form onSubmit={handleSubmit}>
        <h5>New Category</h5>
        <p className="alert alert-warning">
          Please check if the item does not exist before.
        </p>
        <div className="form-group">
          <div class="input-group">
            <span className="input-group-text" id="categ_name">
              <b>{selectedCategory.shortName}</b>/
            </span>
            <input
              type="text"
              className="form-control"
              aria-describedby="categ_name"
              id="category_name"
              value={shortName}
              onChange={(e) => setShortName(e.target.value)}
              placeholder="Short Name"
            />
          </div>
        </div>
        <div className="form-group">
          <div class="input-group">
            <input
              type="text"
              className="form-control"
              aria-describedby="categ_name"
              id="category_name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Full Name(can be same as short name)"
            />
          </div>
        </div>
        <button type="submit" className="main-btn" onClick={handleSubmit}>
          Add
        </button>
        <button
          type="button"
          className="no-border-btn add-more"
          disabled={disableSubmit}
          onClick={handleSubmit}
        >
          Add and another
        </button>
        <button
          type="button"
          className="no-border-btn cancel-btn"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default AddCategory;
