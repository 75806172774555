import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { subscribeToChats } from "../../slices/api/chatsAPI";
import {
  setActiveChat,
  setChatStatusFilter,
  setIdForChatCurrentlyAttending,
} from "../../slices/ChatSlice";
import WhatsAppIcon from "../../assets/img/whatsapp.png";
import WebChatIcon from "../../assets/img/custom_chat.png";
import moment from "moment";
import Constants from "../../constants/Constants";
const ChatHistory = () => {
  const chat = useSelector((state) => state.chat);
  const authUser = useSelector((state) => state.auth);
  const [chatAttending, setChatAttending] = useState();

  const dispatch = useDispatch();

  const handleChatClick = (conversation) => {
    dispatch(setActiveChat(conversation));
  };

  useEffect(() => {
    let unsubscribe = false;

    if (authUser !== null && authUser !== undefined) {
      const isSuperAdmin = authUser.user.userInfo.isSuperAdmin ? true : false;

      unsubscribe = subscribeToChats(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );
    }

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [dispatch, authUser]);

  const handledFilterChatStatus = (status) => {
    dispatch(setChatStatusFilter(status));
  };

  useEffect(() => {
    const chatAttending = chat.conversations.find(
      (item) => item.id == chat.idForChatCurrentlyAttending
    );

    if (
      chatAttending &&
      chatAttending.status == Constants.chatStages.CLOSED &&
      chatAttending.closedBy == Constants.messageSender.AGENT
    ) {
      // alert("This Chat has been closed by client or other window.");
      dispatch(setIdForChatCurrentlyAttending(null));
    }

    const filteredChatAttending = chat.conversations.find(
      (item) =>
        item.agentId == authUser.user.userInfo.mail &&
        item.status != Constants.chatStages.CLOSED &&
        item.status != Constants.chatStages.ARCHIVED
    );

    if (filteredChatAttending) {
      setChatAttending(filteredChatAttending);
    } else {
      setChatAttending(null);
    }
  }, [chat.conversations]);
  return (
    <>
      <div className="chat-history">
        <div className="header-part">
          <h2>Live Chat</h2>
        </div>
        <div className="action-btn">
          <button
            type="button"
            className={`${
              chat.chatStatusFilter == Constants.chatStages.CHAT_OPENED
                ? "active-opt"
                : ""
            }`}
            onClick={() =>
              handledFilterChatStatus(Constants.chatStages.CHAT_OPENED)
            }
          >
            Incoming
          </button>
          <button
            type="button"
            className={`${
              chat.chatStatusFilter == Constants.chatStages.CLOSED
                ? "active-opt"
                : ""
            }`}
            onClick={() => handledFilterChatStatus(Constants.chatStages.CLOSED)}
          >
            Closed
          </button>
        </div>
        <div className="history-container">
          {chatAttending ? (
            <>
              <div
                className={`history-item`}
                onClick={() => handleChatClick(chatAttending)}
              >
                <div className="">
                  <img
                    src={
                      chatAttending.channel == "WHATSAPP"
                        ? WhatsAppIcon
                        : WebChatIcon
                    }
                    alt="Profile broken"
                  />
                </div>
                <div className="message-and-name">
                  <h4 className="name">
                    {chatAttending.channel == "WHATSAPP"
                      ? `+${chatAttending.whatsAppId}`
                      : chatAttending.name}
                  </h4>
                  <p className="last-message">
                    {
                      chatAttending.messages[chatAttending.messages.length - 1]
                        .messageContent
                    }
                  </p>
                </div>
                <div className="time-and-badge">
                  <span className="time">
                    {moment(
                      chatAttending.messages[chatAttending.messages.length - 1]
                        .dateCreated
                    ).format("h:mm A")}
                  </span>
                </div>
              </div>
              <hr></hr>
            </>
          ) : (
            <></>
          )}
          {chat.conversations.map((chatOnHistory) => {
            // Filter the chat history by the status filter
            if (chatOnHistory.status !== chat.chatStatusFilter) {
              return null;
            }

            // Determine if the chat is being attended and if it's the active chat
            const isAttending =
              chatAttending && chatAttending.id === chatOnHistory.id;
            const isActiveChat =
              chat.activeChat && chatOnHistory.id === chat.activeChat.id;

            // Return null if the chat is being attended
            if (isAttending) {
              return null;
            }

            // Render the chat history item
            return (
              <div
                key={chatOnHistory.id}
                className={`history-item ${isActiveChat ? "active-chat" : ""}`}
                onClick={() => handleChatClick(chatOnHistory)}
              >
                <div className="profile-imgs">
                  <img
                    src={
                      chatOnHistory.channel === "WHATSAPP"
                        ? WhatsAppIcon
                        : WebChatIcon
                    }
                    alt="Profile"
                  />
                </div>
                <div className="message-and-name">
                  <h4 className="name">
                    {chatOnHistory.channel === "WHATSAPP"
                      ? `+${chatOnHistory.whatsAppId}`
                      : chatOnHistory.name}
                  </h4>
                  <p className="last-message">
                    {
                      chatOnHistory.messages[chatOnHistory.messages.length - 1]
                        .messageContent
                    }
                  </p>
                </div>
                <div className="time-and-badge">
                  <span className="time">
                    {moment(
                      chatOnHistory.messages[chatOnHistory.messages.length - 1]
                        .dateCreated
                    ).format("h:mm A")}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChatHistory;
