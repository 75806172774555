import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../slices/ToastSlice";

const Toast = () => {
  const { showToast, toastMessage, toastTimer } = useSelector(
    (state) => state.toast
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (showToast && toastMessage && toastTimer) {
      const timeoutId = setTimeout(() => {
        dispatch(setShowToast(false));
        dispatch(setToastMessage(""));
        dispatch(setToastTimer(0));
      }, toastTimer);

      return () => clearTimeout(timeoutId);
    }
  }, [showToast, toastMessage, toastTimer, dispatch]);

  return (
    <div>
      {toastTimer > 0 && (
        <>
          <style>
            {`
              #snackbar {
                visibility: hidden;
                min-width: 250px;
                margin-left: -125px;
                background-color: #333;
                color: #fff;
                text-align: center;
                border-radius: 2px;
                padding: 16px;
                position: fixed;
                z-index: 1;
                left: 50%;
                bottom: 30px;
                font-size: 17px;
              }
              
              #snackbar.show {
                visibility: visible;
                -webkit-animation: fadein 0.5s, fadeout 0.5s ${
                  toastTimer / 1000
                }s;
                animation: fadein 0.5s, fadeout 0.5s ${toastTimer / 1000}s;
              }
              
              @keyframes fadein {
                from { bottom: 0; opacity: 0; }
                to { bottom: 30px; opacity: 1; }
              }
              
              @keyframes fadeout {
                from { bottom: 30px; opacity: 1; }
                to { bottom: 0; opacity: 0; }
              }
            `}
          </style>

          <div id="snackbar" className={showToast ? "show" : ""}>
            {toastMessage}
          </div>
        </>
      )}
    </div>
  );
};

export default Toast;
