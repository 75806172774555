import React from "react";
import CasesTable from "../../components/cases/CasesTable";
import { useNavigate } from "react-router-dom";

const CasesList = () => {
  const navigate = useNavigate();

  const onHandlePageChange = (pageName) => {
    navigate("" + pageName);
  };

  return (
    <>
      <div className="header-part">
        <h2>Cases</h2>
        <button
          type="button"
          className="main-btn"
          onClick={() => onHandlePageChange("create_case")}
        >
          New Case
        </button>
      </div>
      <div className="cases-list-container">
        <CasesTable />
      </div>
    </>
  );
};

export default CasesList;
