import FirebaseCrud from "./firebaseCrud";
import { getDocs, where, query } from "firebase/firestore";
const CATEGORY_NAME = "categories";

class CategoriesService extends FirebaseCrud {
  constructor() {
    super(CATEGORY_NAME);
    this.collectionName = CATEGORY_NAME;
  }
  async readCategories() {
    const categories = await this.readDocument(this.collectionName);
    return categories;
  }

  async readCategoriesWhereUserIsAdmin(email) {
    try {
      const snapshot = await getDocs(
        query(
          this._collectionRef,
          where("Parent", "==", "Main"),
          where("adminEmail", "==", email)
        )
      );
      if (snapshot.empty) {
        return [];
      }
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.log(error);
    }
  }
}

export default CategoriesService;
