import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Account = () => {
  const { user, userInfo } = useSelector((state) => state.auth.user);

  useEffect(() => {
    console.log(userInfo, "   userInfo");
  }, []);
  return (
    <div id="user_account">
      <div className="header-part">
        <h2>Account</h2>
        <button type="button" className="main-btn">
          Update Profile
        </button>
      </div>
      <div className="name-and-profile-pic">
        <p style={{ overflowWrap: "anywhere" }}>
          {userInfo.profilePicture ? (
            <img
              src={`data:image/jpeg;base64,${userInfo.profilePicture}`}
              className="profile-pic"
            />
          ) : (
            <AccountCircleIcon sx={{ fontSize: 130, color: "#b3b3b3" }} />
          )}
        </p>
        <div className="name-side">
          <h2>
            {userInfo.givenName +
              " " +
              userInfo.surname +
              " (" +
              userInfo.displayName +
              ")"}
          </h2>
          <span>{userInfo.jobTitle}</span>
          <span>
            <a href={"mailto:" + userInfo.mail}>{userInfo.mail}</a> |{" "}
            <a href={"tel:" + userInfo.mobilePhone}>{userInfo.mobilePhone}</a>
          </span>
        </div>
      </div>

      <div className="profile-details">
        <h4>More Details</h4>
        <div className="card-content">
          <div>
            Department: <span>{userInfo.department}</span>
          </div>
          <div>
            Delivery Site: <span>{userInfo.officeLocation}</span>
          </div>
          <div>
            Office Phone: <span>{userInfo.businessPhone}</span>
          </div>

          <div>
            Permissions:
            <div className="checkbox-container" style={{ paddingLeft: "2%" }}>
              <div>
                <label htmlFor="super_admin_chk">
                  Is Super Admin : {!!userInfo.isSuperAdmin ? "Yes" : "No"}
                </label>
              </div>
              <div>
                <label htmlFor="admin_chk">
                  Is Admin : {!!userInfo.isAdmin ? "Yes" : "No"}
                </label>
              </div>
              <div>
                <label htmlFor="technician_chk">
                  Is Technician/Specialist:
                  {!!userInfo.isSpecialist ? "Yes" : "No"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
