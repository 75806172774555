import React, { useState, useEffect } from "react";
import MyEditor from "../../../components/Editor";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const FAQsForm = ({
  showForm,
  editMode,
  question,
  answer,
  handleSubmit,
  handleToggleForm,
  setQuestion,
  setAnswer,
}) => {
  return (
    <>
      {showForm && (
        <div className="header-part">
          <button type="button" className="back-btn" onClick={handleToggleForm}>
            <ChevronLeftIcon /> Back
          </button>
          <h2>{editMode ? "Edit FAQ" : "New FAQ"}</h2>
        </div>
      )}

      {showForm && (
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label htmlFor="question">Question:</label>
            <input
              type="text"
              className="form-control"
              id="question"
              placeholder="Enter the question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="answer">Answer:</label>
            <MyEditor comment={answer} setComment={setAnswer} />
          </div>
          <button type="submit" className="main-btn">
            {editMode ? "Update" : "Submit"}
          </button>
        </form>
      )}
    </>
  );
};

export default FAQsForm;
