import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NameResolver from "../../common/NameResolver";
import { subscribeToDepartments } from "../../../slices/api/departmentsApi";

const DetailsTab = () => {
  const dispatch = useDispatch();
  const activeCase = useSelector((state) => state.cases.activeCase);
  const authUser = useSelector((state) => state.auth);
  const departmentsState = useSelector((state) => state.departments);

  const formatFileSize = (size) => {
    if (size < 1024) {
      return `${size} b`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} kb`;
    } else {
      return `${(size / (1024 * 1024)).toFixed(2)} mb`;
    }
  };

  const getDepartmentNameById = () => {
    if (departmentsState && departmentsState.departmentsList) {
      const filteredDepartments = departmentsState.departmentsList.find(
        (category) =>
          category.parentCategory == "main" &&
          category.id == activeCase.department
      );
      return filteredDepartments.name;
    }

    return "Loading..";
  };

  const resolveNameOfCategory = (categoryId) => {
    const dep = departmentsState.departmentsList.find(
      (department) => department.id == categoryId
    );

    return dep ? dep.name : "No found";
  };

  useEffect(() => {
    let unsubscribe = false;
    const fullUrl = window.location.href;
    // const match = fullUrl.match(/single_case\/([^/]+)/);

    if (authUser !== null && authUser !== undefined) {
      const isSuperAdmin = authUser.user.userInfo.isSuperAdmin ? true : false;
      subscribeToDepartments(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );

      // if (match && !activeCase) {
      //   const id = match[1];
      //   subscribeToSingleCase(dispatch, id);
      // } else {
      //   console.log("Id not found in the URL.");
      // }
    }

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [dispatch, authUser]);

  const getExtension = (filename) => {
    return filename.split(".").pop();
  };
  return (
    <div className="details-tab">
      <h5>Request details</h5>

      <div className="details-container">
        <div className="row">
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Request type</td>
                  <td>Normal</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{activeCase.status}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Impact</td>
                  <td className="impact-name">{activeCase.impact}</td>
                </tr>
                <tr>
                  <td>Priority</td>
                  <td>{activeCase.priority}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="details-container">
        <div className="row">
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Requester Name</td>
                  <td className="global-name">
                    <NameResolver email={activeCase.caseRequester} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="details-container">
        <div className="row">
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Department</td>
                  <td>
                    {getDepartmentNameById(activeCase.department.id)}
                    {activeCase.category
                      ? "/" + resolveNameOfCategory(activeCase.category)
                      : ""}
                  </td>
                </tr>
                {/* <tr>
                  <td>Sub-Category</td>
                  <td>{activeCase.subCategory}</td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Assignee</td>
                  {activeCase.assignedTo && activeCase.assignedTo.length > 0
                    ? activeCase.assignedTo.map((assignee) => (
                        <td >
                          <NameResolver email={assignee} />
                        </td>
                      ))
                    : "Not assigned yet."}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="details-container">
        <div className="row">
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Date Created</td>
                  <td>
                    {moment
                      .utc(
                        new Date(activeCase.dateCreated),
                        "MM/DD/YYYY h:mm A"
                      )
                      .format("MMM DD, YYYY HH:mm")}
                  </td>
                </tr>
                <tr>
                  <td>Date Updated</td>
                  <td>
                    {moment
                      .utc(
                        new Date(activeCase.dateUpdated),
                        "MM/DD/YYYY h:mm A"
                      )
                      .format("MMM DD, YYYY HH:mm")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table>
              <tbody>
                <tr>
                  <td>Reported By</td>
                  <td className="global-name">
                    <NameResolver email={activeCase.reportedBy} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="details-container">
        <h5>Description</h5>
        <div
          dangerouslySetInnerHTML={{ __html: activeCase.caseDescription }}
        ></div>
      </div>
      <div className="details-container">
        <h5>Main Attachments</h5>
        {activeCase.attachments && activeCase.attachments.length > 0
          ? activeCase.attachments.map((attachment) => (
              <a
                key={attachment.messageId}
                href="/"
                className="file-item shadow-sm"
                download={attachment.attachmentUrl}
              >
                {attachment.attachmentUrl}
              </a>
            ))
          : "No attachments."}
      </div>
    </div>
  );
};

export default DetailsTab;
