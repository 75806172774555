import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  TableSortLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Search, Clear } from "@mui/icons-material";
import UserDetailsModal from "./UserDetailsModal";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToDepartments } from "../../../slices/api/departmentsApi";
import Constants from "../../../constants/Constants";

const DataTable = ({ data }) => {
  const authUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedDepartment, setSelectedDepartment] = useState();

  const { departmentsList } = useSelector((state) => state.departments);

  useEffect(() => {
    const { isAdmin, department } = authUser.user.userInfo;
    subscribeToDepartments(dispatch, isAdmin, department);
    setSelectedDepartment(department);
  }, [dispatch, authUser]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleViewSingleUser = (user) => {
    setSelectedUser(user);
  };

  const sortedAndFilteredData = data
    .filter((item) => {
      const { department, city, officeLocation, givenName, surname } = item;
      return (
        (!selectedDepartment || department === selectedDepartment) &&
        (givenName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          surname.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    })
    .sort((a, b) => {
      const { key, direction } = sortConfig;
      if (direction === "asc") {
        return a[key].localeCompare(b[key]);
      } else if (direction === "desc") {
        return b[key].localeCompare(a[key]);
      }
      return 0;
    });

  return (
    <div>
      <div>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <Clear
                    onClick={() => setSearchTerm("")}
                    style={{ cursor: "pointer" }}
                  />
                )}
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormControl
          variant="outlined"
          style={{ marginLeft: "20px", width: "300px" }}
        >
          <InputLabel>Department</InputLabel>
          <Select
            value={selectedDepartment ? selectedDepartment : ""}
            onChange={handleDepartmentChange}
            name="department"
            label="Department"
          >
            {departmentsList.map((department) =>
              department.parentCategory == "main" &&
              department.status !== Constants.departmentsStatuses.DISABLED ? (
                <MenuItem key={department.id} value={department.id}>
                  {department.name}
                </MenuItem>
              ) : null
            )}
          </Select>
        </FormControl>
      </div>

      <hr />

      {!selectedUser ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "givenName"}
                    direction={
                      sortConfig.key === "givenName"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("givenName")}
                  >
                    First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "surname"}
                    direction={
                      sortConfig.key === "surname"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("surname")}
                  >
                    Last Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Mobile Phone</TableCell>
                <TableCell>Office Location</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "department"}
                    direction={
                      sortConfig.key === "department"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("department")}
                  >
                    Department
                  </TableSortLabel>
                </TableCell>
                <TableCell>Campus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAndFilteredData.map((item) => (
                <TableRow
                  key={item.id}
                  onClick={() => handleViewSingleUser(item)}
                >
                  <TableCell>{item.givenName}</TableCell>
                  <TableCell>{item.surname}</TableCell>
                  <TableCell>{item.mobilePhone}</TableCell>
                  <TableCell>{item.officeLocation}</TableCell>
                  <TableCell>
                    {
                      departmentsList.find((dep) => dep.id == item.department)
                        .name
                    }
                  </TableCell>
                  <TableCell>{item.city}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <UserDetailsModal
          user={selectedUser}
          users={data}
          departmentName={
            departmentsList.find((dep) => dep.id == selectedUser.department)
              .name
          }
          onBack={() => setSelectedUser(null)}
        />
      )}
    </div>
  );
};

export default DataTable;
