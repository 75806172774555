import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { fetchUsersAsync } from "../../slices/UserSlice";
import { subscribeToDepartments } from "../../slices/api/departmentsApi";
import CategoryList from "./departments/CategoryList";

const Departments = () => {
  const [parent, setParent] = useState("main");
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth);
  const { departmentsList } = useSelector((state) => state.departments);

  useEffect(() => {
    fetchUsersAsync(dispatch);
    subscribeToDepartments(
      dispatch,
      authUser.user.userInfo.isAdmin,
      authUser.user.userInfo.department
    );
  }, [dispatch]);

  const handleGoBack = () => {
    setParent("main");
  };

  return (
    <div>
      <div className="tab-header">
        <h4>
          {parent === "main" ? (
            "Departments"
          ) : (
            <>
              <button type="button" className="back-btn" onClick={handleGoBack}>
                <ChevronLeftIcon />
              </button>
              Subcategories
            </>
          )}
        </h4>
      </div>
      <CategoryList
        parent={parent}
        setParent={setParent}
        departmentsList={departmentsList}
      />
    </div>
  );
};

export default Departments;
