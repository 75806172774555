import React from "react";
import FAQItem from "./FAQItem";

const FAQsList = ({ faqsList, handleEdit, handleDeleteClick }) => {
  return (
    <div className="faqs-container">
      {faqsList.map((faq) => (
        <FAQItem
          key={faq.id}
          faq={faq}
          handleEdit={handleEdit}
          handleDeleteClick={handleDeleteClick}
        />
      ))}
    </div>
  );
};

export default FAQsList;
