import React from "react";
import "./Forbidden.css"; // Importing the CSS file for styling

const Forbidden = () => {
  return (
    <div className="forbidden-container">
      <h1 className="forbidden-title">Access Denied</h1>
      <p className="forbidden-message">
        You do not have permission to view this page. Please check with your
        administrator or return to the dashboard.
      </p>
      <a href="/dashboard" className="back-to-dashboard-link">
        Back to Dashboard
      </a>
    </div>
  );
};

export default Forbidden;
