import { createSlice } from "@reduxjs/toolkit";

import UsersService from "../services/usersService";
const usersService = new UsersService();

const initialState = {
  users: [],
  user: null,
  currentUser: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export const { setUsers, setUser, setCurrentUser } = userSlice.actions;

export const fetchUsersAsync = async (dispatch) => {
  const users = await usersService.readUsers();
  dispatch(setUsers(users));
};

export const fetchUserAsync = async (dispatch, id) => {
  const user = await usersService.readDocumentWithId(id);
  dispatch(setUser(user));
};

export const addUserAsync = async (user) =>
  await usersService.addUser(
    user.email,
    user.role,
    user.department,
    user.addedBy,
    user.fullname
  );

export const updateUserAsync = async (id, user, updatedBy) =>
  await usersService.modifyUser(id, user, updatedBy);

export const deleteUserAsync = async (id) => await usersService.deleteUser(id);

export const listenToUsersSubscription = (dispatch) => {
  return usersService.listenDocs((users) =>
    dispatch(setUsers(users), (error) => console.log(error))
  );
};

export const fetchCurrentUserAsync = async (dispatch, id) => {
  const user = await usersService.readCurrentUser(id);
  dispatch(setCurrentUser(user));
};

export const modifyUserAsync = async (id, newUserInfo) => {
  await usersService.modifyUser(id, newUserInfo, id);
};

export default userSlice;
