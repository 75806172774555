import React, { Component, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const MyEditor = ({ comment, setComment, isEditable, setTextContent }) => {
  const handleChange = (content, delta, source, editor) => {
    if (setTextContent) setTextContent(editor.getText(content));
    setComment(content);
  };

  return (
    <div>
      <ReactQuill
        value={comment}
        onChange={handleChange}
        placeholder="Enter text here"
        readOnly={isEditable ? !isEditable : false}
      />
    </div>
  );
};

export default MyEditor;
