import React from 'react';
import { Chart } from 'react-google-charts';

const BarChart = ({ barData, barOptions}) => {
  return (
    <>
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="250px"
            data={barData}
            options={barOptions}
        />
    </>
  )
}

export default BarChart