import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDialog from "../../DeleteDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCategory from "./AddCategory";
import {
  disableSubcategory,
  updateSubcategory,
} from "../../../slices/api/departmentsApi";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../../slices/ToastSlice";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../../constants/Constants";

const SubCategoryList = ({ subCategories, selectedCategory }) => {
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [confirmDeleteShow, setConfirmDeleteShow] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editItemId, setEditItemId] = useState(null);
  const [editedName, setEditedName] = useState("");
  const authUser = useSelector((state) => state.auth);
  const [isSubmittingEdit, setIsSubmittingEdit] = useState(false);
  const dispatch = useDispatch();

  const handleShowAddCateg = () => {
    setShowAddCategory(false);
  };

  const handleShowDelete = (id) => {
    setConfirmDeleteShow(true);
    setDeleteItemId(id);
  };

  const handleEdit = (id, name) => {
    setEditItemId(id);
    setEditedName(name);
  };

  const handleCancelEdit = () => {
    setEditItemId(null);
    setEditedName("");
  };

  const handleSaveEdit = async (id, categoryName) => {
    try {
      setIsSubmittingEdit(true);
      await updateSubcategory(id, {
        name: editedName,
        dateUpdated: new Date().toString(),
        auditTrail: {
          auditType: Constants.auditType.CATEGORY_UPDATE,
          actionPerson: authUser.user.userInfo.mail,
          categoryUpdateData: {
            originalName: categoryName,
            currentName: editedName,
          },
          dateCreated: new Date().toString(),
        },
      });
      setEditItemId(null);
      setEditedName("");

      dispatch(setToastMessage("Successfully update subcategory name."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } catch (error) {
      console.error(error);
      dispatch(
        setToastMessage(
          "An error occurred while trying to save the edited name."
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } finally {
      setIsSubmittingEdit(false);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      const payload = {
        status: Constants.departmentsStatuses.DISABLED,
        dateUpdated: new Date().toString(),
        auditTrail: {
          auditType: Constants.auditType.DEPARTMENT_STATUS_UPDATE,
          actionPerson: authUser.user.userInfo.mail,
          caseStatusData: {
            status: Constants.departmentsStatuses.DISABLED,
          },
          dateCreated: new Date().toString(),
        },
      };
      await disableSubcategory(id, payload);
      dispatch(setToastMessage("Successfully removed the subcategory."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } catch (error) {
      console.error(error);
      dispatch(
        setToastMessage(
          "An error occurred while trying to remove the subcategory."
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  return (
    <div className="sub-cate">
      <ul className="list-unstyled shadow">
        <div className="header-part">
          <h4>{selectedCategory.shortName}/Categories</h4>
          <div className="head-btn-container">
            <button
              type="button"
              className="main-btn"
              onClick={() => setShowAddCategory(true)}
            >
              Add New Category
            </button>
            {showAddCategory && (
              <AddCategory
                selectedCategory={selectedCategory}
                handleShowAddCateg={handleShowAddCateg}
              />
            )}
          </div>
        </div>
        {subCategories.length > 0 ? (
          subCategories.map((category) => {
            if (category.status !== Constants.departmentsStatuses.DISABLED) {
              return (
                <li key={category.id}>
                  {editItemId === category.id ? (
                    <>
                      <input
                        type="text"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        className="categ-input"
                      />
                      <div className="sub-action-btns">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveEdit(category.id, category.name)
                          }
                          className="main-btn"
                          disabled={isSubmittingEdit}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          onClick={handleCancelEdit}
                          className="no-border-btn"
                          disabled={isSubmittingEdit}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <span className="categ-name">{category.name}</span>
                      <div className="sub-action-btns">
                        <button
                          type="button"
                          onClick={() => handleEdit(category.id, category.name)}
                        >
                          <EditIcon />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleShowDelete(category.id)}
                        >
                          <DeleteOutlineIcon />
                        </button>
                      </div>
                    </>
                  )}
                </li>
              );
            }
            return null;
          })
        ) : (
          <div>There are no subcategories for this department</div>
        )}
      </ul>
      {confirmDeleteShow && (
        <DeleteDialog
          message="Do you really want to delete this subcategory? This process cannot be undone."
          onDelete={() => handleDeleteCategory(deleteItemId)}
          onCancel={() => setConfirmDeleteShow(false)}
        />
      )}
    </div>
  );
};

export default SubCategoryList;
