import React from 'react'
import DoneIcon from '@mui/icons-material/Done';

const SuccessDialog = ({ message, onClose}) => {
  return (
    <div className='success-dialog'>
        <div className="dialog-content shadow">
            <div className="icon-part">
                <DoneIcon fontSize='large'/>
            </div>
            <h2>Success</h2>
            <p>{message}</p>
            <button type='button' className='main-btn' onClick={onClose}>Ok</button>
        </div>
    </div>
  )
}

export default SuccessDialog