import React from "react";
import ClearIcon from "@mui/icons-material/Clear";

const DeleteDialog = ({ message, onDelete, onCancel }) => {
  const handleOnDeleteButtonClick = () => {
    onDelete();
  };

  return (
    <div className="delete-dialog">
      <div className="dialog-content shadow">
        <div className="icon-part">
          <ClearIcon fontSize="large" />
        </div>
        <h2>Are you sure?</h2>
        <p>{message}</p>
        <div className="dialog-action-btns">
          <button
            type="button"
            className="cancel-btn no-border-btn"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="delete-btn main-btn"
            onClick={handleOnDeleteButtonClick}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDialog;
