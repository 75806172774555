import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  departmentsList: [],
  openDepartment: {},
};

const departmentSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    setDepartments: (state, action) => {
      state.departmentsList = action.payload;
    },
    setOpenDepartment: (state, action) => {
      state.openDepartment = action.payload;
    },
  },
});

export const { setDepartments, setOpenDepartment } = departmentSlice.actions;

export default departmentSlice;
