import React, { useEffect } from "react";
import DataTable from "./users/DataTable";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../slices/api/usersApi";

const Users = () => {
  const authUser = useSelector((state) => state.auth);
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllUsers(
      dispatch,
      authUser.user.userInfo.isAdmin,
      authUser.user.userInfo.department
    );
  }, [dispatch, authUser]);

  return (
    <div>
      {/* <h4>Users</h4> */}
      {users && <DataTable data={users} />}
    </div>
  );
};

export default Users;
