import {
  addDoc,
  collection,
  doc,
  getDocs,
  setDoc,
  query,
  where,
  getDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/firebase";

/**
 * @author Fani Keorapetse
 * @private
 * @description Firebase crud defines the basic create,read,update,delete operations based on firebase v9
 */

// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

class FirebaseCrud {
  _collectionName;
  _docs = [];
  docRef;
  _collectionRef;

  constructor(collectionName) {
    this._collectionName = collectionName;
    this._collectionRef = collection(db, this._collectionName);
  }

  _docReference = (id) => doc(db, this._collectionName, id);

  async addDocument(payload, addedBy, id = null) {
    console.log(payload, !id);
    payload["dateCreated"] = Date.now();
    if (!id) {
      this.docRef = await addDoc(this._collectionRef, {
        ...payload,
        addedBy,
      });
    } else {
      this.docRef = this._docReference(id);
      console.log("COLLECTION REF 👌👌👌👌", this.docRef);
      await setDoc(this.docRef, { ...payload, addedBy });
    }
  }

  async documentExist(key, value) {
    // prettier-ignore
    const q = query(this._collectionRef, where(key, "==", value))
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      return querySnapshot.docs[0].data();
    }
    return {};
  }

  async readDocument() {
    const snapshot = await getDocs(this._collectionRef);
    this._docs = snapshot.docs.map((doc) => {
      const data = doc.data();
      return { id: doc.id, ...data };
    });

    return this._docs;
  }

  listenDocs(onSuccess, onError) {
    const unsubscribe = onSnapshot(this._collectionRef, {
      next: (querySnapshot) => {
        const docs = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return { id: doc.id, ...data };
        });
        onSuccess(docs);
      },
      error: (error) => {
        onError(error);
      },
    });
    return unsubscribe;
  }

  async readDocumentWithId(id) {
    const snapshot = await getDoc(this._docReference(id));
    if (snapshot.exists()) {
      return snapshot.data();
    }
    return null;
  }

  async updateDocument(payload, id, updatedBy) {
    try {
      await updateDoc(this._docReference(id), {
        ...payload,
        updatedBy,
        dateUpdated: Date.now(),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async deleteDocument(id) {
    return await deleteDoc(this._docReference(id));
  }
}

export default FirebaseCrud;
