import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const FAQItem = ({ faq, handleEdit, handleDeleteClick }) => {
  return (
    <div className="faq-item" key={faq.id}>
      <h3 className="faq-question">{faq.question}</h3>
      <div
        className="faq-content"
        dangerouslySetInnerHTML={{ __html: faq.answer }}
      ></div>
      <div className="action-btns">
        <button className="edit-btn" onClick={() => handleEdit(faq.id)}>
          <EditIcon fontSize="small" /> Edit
        </button>
        <button
          className="delete-btn"
          onClick={() => handleDeleteClick(faq.id)}
        >
          <DeleteIcon fontSize="small" /> Delete
        </button>
      </div>
    </div>
  );
};

export default FAQItem;
