import React from 'react'
import Chart from 'react-google-charts'

const StackedChart = ({stackedChartData, stackedChartOptions}) => {
	return (
		<>
			<Chart 
				width={"100%"}
				height={"250px"}
				chartType='BarChart'
				loader={<div>Loading Chart...</div>}
				data={stackedChartData}
				options={stackedChartOptions}
				rootProps={{ 'data-testid': '1' }}
			/>
		</>	
	)
}

export default StackedChart