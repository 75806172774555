import { Timestamp } from "firebase/firestore";
import FirebaseCrud from "./firebaseCrud";
import axios, { AxiosHeaders } from "axios";
const COLLECTION_NAME = "users";

class UsersService extends FirebaseCrud {
  user;
  constructor() {
    super(COLLECTION_NAME);
  }
  async addUser(email, role, department, addedBy, fullname) {
    const payload = {
      email,
      role,
      department,
      fullname,
    };
    const externalUser = await axios.get(
      "http://localhost:7001/users?email=" + email,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    if (
      externalUser.data.length === 0 ||
      (externalUser.data && Object.keys(externalUser.data).length === 0)
    ) {
      throw Error("User doesn't exist in the external database");
    }
    const existingPayload = await this.documentExist("email", email);

    if (Object.keys(existingPayload).length > 0) {
      return existingPayload;
    }
    await this.addDocument(payload, addedBy, email);
    return payload;
  }
  async readUsers() {
    const users = await this.readDocument();
    return users;
  }

  async readCurrentUser(id) {
    const user = await this.readDocumentWithId(id);
    return user;
  }

  async modifyUser(id, newUserInfo, updatedBy) {
    await this.updateDocument(newUserInfo, id, updatedBy);
  }
  async deleteUser(id) {
    console.log("DELETE USER", id);
    return await this.deleteDocument(id);
  }
  _getRoles() {}
  modifyUserRole(email, role) {}
}

export default UsersService;
