import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CommentIcon from "@mui/icons-material/Comment";
import DescriptionIcon from "@mui/icons-material/Description";
import MyEditor from "../../Editor";
import DragAndDrop from "../../DragAndDropBox";
import StorageService from "../../../services/storageService";
import Constants from "../../../constants/Constants";
import { v4 as uuidv4 } from "uuid";
import {
  sendCaseUpdateEmail,
  updateCaseMasterData as updateCaseMasterDataApi,
  updateCaseWithComment as updateCaseWithCommentApi,
} from "../../../slices/api/casesApi";
import { setActiveCase } from "../../../slices/CasesSlice";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../../slices/ToastSlice";
import NameResolver from "../../common/NameResolver";

const ConversationTab = () => {
  const storageService = new StorageService();
  const dispatch = useDispatch();
  const authenticatedUser = useSelector((state) => state.auth.user);
  const cases = useSelector((state) => state.cases);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [comment, setComment] = useState(null);
  const [files, setFiles] = useState([]);

  const updateCaseWithComments = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!comment && files.length === 0) {
      return false;
    }

    const updateCaseData = {
      commentId: uuidv4(),
      dateCreated: new Date().toString(),
      isRead: false,
      commenter: Constants.messageSender.AGENT,
      commenterId: authenticatedUser.userInfo.mail,
    };

    if (comment) {
      updateCaseData.commentContent = comment;
    }

    updateCaseData.commentAttachmentsIds = [];

    try {
      const uploadFiles = async () => {
        for (const file of files) {
          try {
            const uploadFileResult = await storageService.uploadFile(
              file,
              Constants.typeOfService.CASES
            );
            updateCaseData.commentAttachmentsIds.push(uploadFileResult);
          } catch (error) {
            dispatch(
              setToastMessage("An error occurred while uploading attachment.")
            );
            dispatch(setToastTimer(3000));
            dispatch(setShowToast(true));
            console.log(error);
            return;
          }
        }
      };

      await uploadFiles();
      console.log(cases.activeCase.id, updateCaseData);
      await updateCaseWithCommentApi(cases.activeCase.id, updateCaseData);
      await sendCaseUpdateEmail(
        [cases.activeCase.caseRequester],
        cases.activeCase.caseNumber,
        comment
      );

      setComment("");
      setFiles([]);
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      dispatch(
        setToastMessage("An error occurred while trying to update the case")
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (cases.activeCase) {
      const updatedActiveCase = cases.casesList.find(
        (item) => item.id === cases.activeCase.id
      );
      dispatch(setActiveCase(updatedActiveCase));
    }
  }, [cases]);

  const checkIfAssigneeOrAdmin = () => {
    const isAdmin =
      authenticatedUser.userInfo.isAdmin ||
      authenticatedUser.userInfo.isSuperAdmin;

    const isAssignee = cases.activeCase.assignedTo.find(
      (assignee) => assignee == authenticatedUser.userInfo.mail
    );

    return isAdmin || !!isAssignee;
  };

  return (
    <div className="conversation-tab">
      <div className="timeline-container">
        <div className="description comment">
          <div className="timeline-indicator">
            <span className="comm-type">Description</span>
            <span className="circle">
              <DescriptionIcon fontSize="inherit" />
            </span>
          </div>
          <div className="comment-header">
            <p>
              <span className="global-name">
                <NameResolver email={cases.activeCase.caseRequester} />
              </span>{" "}
              -{" "}
              {moment
                .utc(
                  new Date(cases.activeCase.dateCreated),
                  "MM/DD/YYYY h:mm A"
                )
                .format("MMM DD, YYYY HH:mm")}
            </p>
          </div>
          <div
            className="comment-content"
            dangerouslySetInnerHTML={{
              __html: cases.activeCase.caseDescription,
            }}
          ></div>
        </div>
        {checkIfAssigneeOrAdmin}
        {cases.activeCase.comments.length === 0 ? (
          <div>No comments</div>
        ) : (
          cases.activeCase.comments.map((comment) => (
            <div key={comment.commentId} className="comment">
              <div className="timeline-indicator">
                <span className="comm-type">Today</span>
                <span className="circle">
                  <CommentIcon fontSize="inherit" />
                </span>
              </div>
              <div className="comment-header">
                <p>
                  <span className="global-name">
                    <NameResolver email={comment.commenterId} />
                  </span>{" "}
                  -{" "}
                  {moment
                    .utc(new Date(comment.dateCreated), "MM/DD/YYYY h:mm A")
                    .format("MMM DD, YYYY HH:mm")}
                </p>
              </div>
              <div
                className="comment-content"
                dangerouslySetInnerHTML={{ __html: comment.commentContent }}
              ></div>
            </div>
          ))
        )}
      </div>
      {cases.activeCase.status == Constants.caseStatuses.OPEN ? (
        <div className="comment-sec shadow">
          <h4>New Comment</h4>
          <MyEditor
            comment={comment}
            setComment={setComment}
            isEditable={checkIfAssigneeOrAdmin()}
            id="add_new_comment"
          />
          <h5 className="attachments-header">Attachments</h5>
          <DragAndDrop
            files={files}
            setFiles={setFiles}
            isEditable={checkIfAssigneeOrAdmin()}
          />

          <div className="btns-container">
            <button
              type="button"
              className="main-btn"
              disabled={isSubmitting || !checkIfAssigneeOrAdmin()}
              onClick={updateCaseWithComments}
            >
              {isSubmitting ? "Updating..." : "Save"}
            </button>
            <button
              type="button"
              disabled={!checkIfAssigneeOrAdmin()}
              className="no-border-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ConversationTab;
