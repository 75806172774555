import { Outlet } from "react-router-dom";

const Case = () => {
  return (
    <>
      <div id="cases_container">
        <Outlet />
      </div>
    </>
  );
};

export default Case;
