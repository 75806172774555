import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  deleteDoc,
  arrayUnion,
  getFirestore,
  updateDoc,
  getDoc,
  setDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setFAQsList } from "../FAQSlice";

export const addFAQToFirestore = async (faqData) => {
  try {
    const docRef = await addDoc(collection(db, "faqs"), faqData);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

export const subscribeToAllFAQs = async (
  dispatch,
  userDepartment,
  isSuperAdmin
) => {
  const faqsRef = collection(db, "faqs");
  let q = null;

  if (isSuperAdmin) {
    q = query(faqsRef);
  } else {
    q = query(faqsRef, where("department", "==", userDepartment));
  }

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const faqs = snapshot.docs.map((doc) => {
      let faqData = doc.data();
      faqData.dateCreated = faqData.dateCreated.toString();
      faqData.dateUpdated = faqData.dateUpdated
        ? faqData.dateUpdated.toString()
        : "";

      return { id: doc.id, ...faqData };
    });

    dispatch(setFAQsList(faqs));
  });

  return unsubscribe;
};

export const fetchFAQById = async (faqId) => {
  try {
    const faqDocRef = doc(db, "faqs", faqId);
    const faqDocSnapshot = await getDoc(faqDocRef);

    if (faqDocSnapshot.exists()) {
      return {
        id: faqDocSnapshot.id,
        ...faqDocSnapshot.data(),
      };
    } else {
      console.log("FAQ document not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching FAQ by ID:", error);
    return null;
  }
};

// Update FAQ in Firestore
export const updateFAQInFirestore = async (faqId, question, answer) => {
  try {
    const faqRef = doc(db, "faqs", faqId);
    await updateDoc(faqRef, { question, answer });
    console.log("FAQ updated with ID: ", faqId);
  } catch (error) {
    // If an error occurs during update, delete the newly created document
    await deleteDoc(doc(db, "faq", faqId));
    console.error("Failed to update FAQ: ", error);
    throw new Error("Failed to update FAQ: " + error.message);
  }
};

// Delete FAQ from Firestore
export const deleteFAQFromFirestore = async (faqId) => {
  try {
    await deleteDoc(doc(db, "faqs", faqId));
    console.log("FAQ deleted with ID: ", faqId);
  } catch (error) {
    throw new Error("Failed to delete FAQ: " + error.message);
  }
};
