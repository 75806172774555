import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserDetailsByUserPrincipalName } from "../../slices/api/usersApi";

const NameResolver = ({ email }) => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileData = await getUserDetailsByUserPrincipalName(email);
        setProfile(profileData);
      } catch (error) {
        if (
          error.message ===
          "User not existing. Newly added users take a couple of hours to be available on Service Desk."
        ) {
          setProfile({ id: "notFound", data: null });
        } else {
          console.error("Error fetching user profiles:", error);
        }
      }
    };

    fetchUserProfile();
  }, [email]);

  const handleViewAssigneeProfile = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      {profile && profile.id != "notFound" ? (
        <div key={profile.id}>
          {profile.data ? (
            <div className="global-name">
              <Link
                to={`/user_profile_view/${profile.id}`}
                onClick={handleViewAssigneeProfile}
              >
                {profile.data.displayName}
              </Link>
            </div>
          ) : (
            <span className="global-name">{email}</span>
          )}
        </div>
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default NameResolver;
