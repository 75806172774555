import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  getDocs,
  deleteDoc,
  arrayUnion,
  getFirestore,
  updateDoc,
  setDoc,
  getDoc,
  limit,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Constants from "../../constants/Constants";
import { setUsers } from "../UserSlice";

export const getUserDetailsByUserPrincipalName = async (userPrincipalName) => {
  try {
    const userDocRef = doc(db, "users", userPrincipalName);
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      return {
        data: docSnapshot.data(),
        id: docSnapshot.id,
      };
    } else {
      return {
        status: "notFound",
        data: null,
        id: null,
      };
    }
  } catch (error) {
    console.error("Error retrieving user details:", error);
    return {
      status: "error",
      data: null,
      id: null,
      message: error.message,
    };
  }
};

export const getUserByUserPrincipalName = async (userPrincipalName) => {
  try {
    const usersCollectionRef = collection(db, "users");

    const q = query(
      usersCollectionRef,
      where("userPrincipalName", "==", userPrincipalName),
      limit(1)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();
      return userData;
    } else {
      throw new Error(
        "User not existing. Newly added users take couple of hours to be avaliable on Service Desk."
      );
    }
  } catch (error) {
    console.error("Error updating array:", error);
    throw new Error(error);
  }
};

export const getAllUsers = async (dispatch, userDepartment, isSuperAdmin) => {
  const usersCollectionRef = collection(db, "users");
  let q = null;

  if (isSuperAdmin) {
    q = query(usersCollectionRef);
  } else {
    q = query(usersCollectionRef, where("department", "==", userDepartment));
  }

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const users = snapshot.docs.map((doc) => {
      const userData = doc.data();
      return { id: doc.id, ...userData };
    });

    dispatch(setUsers(users));
  });

  return unsubscribe;
};

export const updateUserByUserPrincipalName = async (
  userPrincipalName,
  userData
) => {
  try {
    const usersCollectionRef = collection(db, "users");
    const userDocRef = doc(usersCollectionRef, userPrincipalName);

    const updateData = { ...userData };

    if (userData.hasOwnProperty("auditTrail")) {
      updateData.auditTrail = arrayUnion(userData.auditTrail);
    }

    await updateDoc(userDocRef, updateData);

    console.log(
      `User with principal name ${userPrincipalName} updated successfully.`
    );
  } catch (error) {
    console.error(`Error updating user: ${error}`);
    throw new Error("Error occurred while trying to update user.");
  }
};
