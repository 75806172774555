import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openFAQ: {},
  faqsList: [],
};

const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    setOpenFAQ: (state, action) => {
      state.openFAQ = action.payload;
    },
    setFAQsList: (state, action) => {
      state.faqsList = action.payload;
    },
  },
});

export const { setOpenFAQ, setFAQsList } = faqsSlice.actions;

export default faqsSlice;
