import {
  collection,
  query,
  where,
  onSnapshot,
  runTransaction,
  getDoc,
  addDoc,
  doc,
  deleteDoc,
  arrayUnion,
  getFirestore,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setDepartments } from "../DepartmentSlice";

export const subscribeToDepartments = (dispatch, isAdmin, userDepartment) => {
  const categoriesCollectionRef = collection(db, "categories");

  const q = isAdmin
    ? query(categoriesCollectionRef)
    : query(categoriesCollectionRef, where("name", "==", userDepartment));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const categories = snapshot.docs.map((doc) => {
      let categoriesData = doc.data();

      categoriesData.dateCreated = categoriesData.dateCreated.toString();
      categoriesData.dateUpdated = categoriesData.dateUpdated.toString();

      return { id: doc.id, ...categoriesData };
    });

    dispatch(setDepartments(categories));
  });

  return unsubscribe;
};

export const addSubcategory = async (categoryData) => {
  try {
    const docRef = await addDoc(collection(db, "categories"), categoryData);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

export const updateSubcategory = async (categoryId, categoryData) => {
  try {
    const categoriesRef = collection(db, "categories");
    const updateData = { ...categoryData };

    if (updateData.hasOwnProperty("auditTrail")) {
      const docRef = doc(categoriesRef, categoryId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        if (!existingData.auditTrail) {
          existingData.auditTrail = [];
        }
        existingData.auditTrail.push(updateData.auditTrail);
        updateData.auditTrail = existingData.auditTrail;
      }
    }

    const docRef = doc(categoriesRef, categoryId);
    await updateDoc(docRef, updateData);
    console.log("Document updated with ID: ", categoryId);
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

export const getDepartmentById = async (departmentId) => {
  const departmentsRef = collection(db, "categories");
  try {
    const docRef = doc(departmentsRef, departmentId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw new Error("Department not found");
    }
  } catch (error) {
    console.error("Error getting document: ", error);
    throw error;
  }
};

export const disableSubcategory = async (categoryId, categoryData) => {
  try {
    const docRef = doc(db, "categories", categoryId);
    await updateDoc(docRef, categoryData);
    console.log(`Category with ID ${categoryId} has been disabled.`);
  } catch (error) {
    console.error("Error disabling category: ", error);
    throw error;
  }
};

export const updateCategoryData = async (categoryId, categoryData) => {
  try {
    const docRef = doc(db, "categories", categoryId);
    await updateDoc(docRef, categoryData);
    console.log(`Category with ID ${categoryId} has been disabled.`);
  } catch (error) {
    console.error("Error disabling category: ", error);
    throw error;
  }
};
