import React, { useState, useEffect } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { useNavigate } from "react-router-dom";
import { setOpenFAQ } from "../../slices/FAQSlice";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToAllFAQs } from "../../slices/api/faqApi";

const ListFAQs = () => {
  const { faqsList } = useSelector((state) => state.faqs);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth);

  useEffect(() => {
    subscribeToAllFAQs(
      dispatch,
      authUser.user.userInfo.isAdmin,
      authUser.user.userInfo.department
    );
  }, [dispatch, authUser]);

  const handleView = (faqId) => {
    const faqToOpen = faqsList.find((faq) => faq.id == faqId);
    dispatch(setOpenFAQ(faqToOpen));
    navigate(`view/${faqId}`);
  };

  return (
    <div id="faqs">
      <h2>FAQs</h2>

      <div className="faqs-container">
        {faqsList
          ? faqsList.map((faq) => (
              <div className="faq-item" key={faq.id}>
                <h3 className="faq-question">{faq.question}</h3>
                <div
                  className="faq-content"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                ></div>
                {
                  <div className="action-btns">
                    <button
                      className="edit-btn"
                      onClick={() => handleView(faq.id)}
                    >
                      <OpenInFullIcon fontSize="xsmall" />
                      {"\u00A0"}
                      Open
                    </button>
                  </div>
                }
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default ListFAQs;
