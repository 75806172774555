import React from "react";
import ClearIcon from "@mui/icons-material/Clear";

const ConfirmationDialog = ({
  message,
  onCallback,
  onCancel,
  buttonText,
  buttonClassName,
}) => {
  const handleOnConfirmButtonClick = (event) => {
    event.stopPropagation();
    onCallback();
    onCancel();
  };

  return (
    <div className="delete-dialog">
      <div className="dialog-content shadow">
        <div className="icon-part">
          <ClearIcon fontSize="large" />
        </div>
        <h2>Are you sure?</h2>
        <p>{message}</p>
        <div className="dialog-action-btns">
          <button
            type="button"
            className="cancel-btn no-border-btn"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`${buttonClassName} main-btn`}
            onClick={handleOnConfirmButtonClick}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
