import React from 'react';
import Constants from "../../constants/Constants";

const ImagePreviewPopup = ({ selectedImage, onClose }) => {
  return (
    <div className="image-popup">
      
      <div className="popup-content">
        <button onClick={() => onClose(null)} className="close-button">
            Close
        </button>
        <img
          src={`${Constants.STORAGE_BUCKET_URL}/${Constants.typeOfService.CHATS}/${selectedImage}`}
          alt={selectedImage}
        />
      </div>
    </div>
  );
};

export default ImagePreviewPopup;