import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TextField } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { globalActions } from "../slices/globalSlice";
import UsersService from "../services/usersService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NORMAL_USER_ID = "FBDLZWsvk56hMtwkLpui";

const UserDialog = () => {
  const usersService = new UsersService();
  // prettier-ignore
  const isUserDialogOpen = useSelector((state) => state.global.isAddUserDialogShown);
  const dispatch = useDispatch();
  const userEmailRef = useRef();
  const departmentRef = useRef();

  const handleAddUser = async () => {
    const payload = {
      addedBy: JSON.parse(localStorage.getItem("user")).email,
      email: userEmailRef.current.value,
      department: departmentRef.current.value,
      role: NORMAL_USER_ID,
    };

    await usersService.addUser(
      payload.email,
      payload.role,
      payload.department,
      payload.addedBy
    );

    // TODO: Add the message to the global toast messages variable,
    // TODO: Add a try catch to also catch any errors and updated the message prob,
    // TODO: Disable the buttons to prevent multiple clicks and also show the loading spinner on the buttons
    // TODO: Update the department to be a select instead of textInput.

    dispatch(globalActions.toggleUserDialog());
  };

  const handleClose = () => {
    dispatch(globalActions.toggleUserDialog());
  };

  return (
    <Dialog
      open={isUserDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Fill in the user information below."}</DialogTitle>
      <DialogContent>
        <TextField
          required
          id="filled-required"
          label="Email"
          type="email"
          inputRef={userEmailRef}
          size="small"
          variant="filled"
          color="success"
          className="input-style"
        />
        <TextField
          required
          id="filled-required"
          label="Department"
          color="success"
          size="small"
          inputRef={departmentRef}
          variant="filled"
          className="input-style"
        />
        {/* <TextField
          required
          id="filled-required"
          label="Email"
          focused
          ref={userEmailRef}
          // defaultValue="User Email"
          variant="filled"
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          className=""
          color="success"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button onClick={handleAddUser} className="main-btn">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
