import React, {useEffect} from "react";
import DashboardTable from "../../components/charts/DashboardTable";
import PieChart from "../../components/charts/PieChart";
import BarChart from "../../components/charts/BarChart";
import StackedChart from "../../components/charts/StackedChart";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToCases } from "../../slices/api/casesApi";
import { subscribeToDepartments } from "../../slices/api/departmentsApi";
import Constants from "../../constants/Constants";

// === Open Cases by Department ===
const data1 = [
	["Department", "Cases Open"],
	["Software", 17],
	["Human Resource", 23],
	["Finance", 47],
	["Internet", 42],
	["Other", 77]
];

const options1 = {
	pieSliceText: "none",
	legend: 'bottom'
};

const openByPriorityOptions = {
	pieSliceText: "none",
	colors: ["#e6e6e6", '#b3b3b3', '#ff6600', '#df2e2e', '#29bb89' ],
	legend: 'bottom',
}

// === SLA Violation by Agent ===
const SLAViolationData = [
	["Agents", "Test"],
	["Unassigned", 5],
	["Agent 1", 8],
	["Agent 2", 1],
	["Agent 3", 2],
	["Agent 4", 7],
	["Agent 5", 9]
];

const SLAViolationOptions = {
	legend: { position: 'none' },
	bar: { groupWidth: '20%' },
	colors: ['#df2e2e']
};

// === SLA Violated Cases ===
const openCases = 50;
const overdueCase = 20;
const notOverdue = openCases - overdueCase;

const SLAViolatedCasesData = [
	["cases", "Not Overdue", "Overdue"],
	["", notOverdue, overdueCase],
];

const SLAViolatedCasesOptions = {
	legend: { position: 'none'},
	isStacked: true,
	colors: ['#CCC', '#df2e2e'],
	hAxis: {
		title: "Total Cases",
		minValue: 0,
		maxValue: openCases,
	},
	bar: { groupWidth: '20%' },
}

// === Completed Cases in the last 30 days ===

const getLast30Days = () => {
	const today = new Date();
	const days = [];
	for (let i = 29; i >= 0; i--) {
	  const date = new Date(today);
	  date.setDate(date.getDate() - i);
	  const formattedDate = date.toLocaleString('default', { month: 'short', day: 'numeric' });
	  days.push(formattedDate);
	}
	return days;
};

const completedCases = [25,14,24,45,85,148,59,93,47,78,0,45,0,0,45,59,93,47,78,0,45,5,14,24,45,85,45,85];

const completedCasesOptions = {
	legend: { position: 'none' },
	bar: { groupWidth: '70%' },
	colors: ['#29bb89']
}
const Dashboard = () => {
	const dispatch = useDispatch();
	const cases = useSelector((state) => state.cases.casesList);
	const authUser = useSelector((state) => state.auth);
	const departmentsState = useSelector((state) => state.departments);

	const last30Days = getLast30Days();

	// If completedCases array doesn't have data for all 30 days, fill missing days with 0
	const completedCasesData = last30Days.map((day, index) => {
		const completed = completedCases[index] || 0;
		return [day, completed];
	});
	
	useEffect(() => {
		let unsubscribe = false;
	
		if (authUser !== null && authUser !== undefined) {
		  const isSuperAdmin = authUser.user.userInfo.isSuperAdmin ? true : false;
	
		  unsubscribe = subscribeToCases(
			dispatch,
			authUser.user.userInfo.department,
			isSuperAdmin
		  );
	
		  subscribeToDepartments(
			dispatch,
			isSuperAdmin,
			authUser.user.userInfo.department
		  );
		}
	
		return () => {
		  if (typeof unsubscribe === "function") {
			unsubscribe();
		  }
		};
	}, [dispatch, authUser]);

	const completedData = [['Date', 'Completed Cases'], ...completedCasesData];

	const countCasesByPriority = (priority) => {
		return cases.filter(caseItem => caseItem.priority === priority && caseItem.status === Constants.caseStatuses.OPEN).length;
	}

	// === Open Cases by Priority ===
	const openByPriorityData = [
		["Priority", "Cases"],
		["Low", countCasesByPriority('low')],
		["Medium", countCasesByPriority('medium')],
		["High", countCasesByPriority('high')],
		["Emergency", countCasesByPriority('emergency')],
		["Normal", countCasesByPriority('normal')],
	];

	return (
		<div id="dashboard">
			<div className="header-part">
				<h2>Service Desk Dashboard {cases.length}</h2>
				{/* <button type="button" className="main-btn">
				New Case
				</button> */}
			</div>
			<div className="charts">
				<div className="chart-card shadow-sm">
					<div className="card-header">
						<h4>Cases by Channel</h4>
					</div>
					<div className="chart-container">
						<DashboardTable />
					</div>
				</div>
				<div className="chart-card shadow-sm">
					<div className="card-header">
						<h4>Open by Departments</h4>
					</div>
					<div className="chart-container">
						<PieChart categData={data1} categOptions={options1} />
					</div>
				</div>
				<div className="chart-card shadow-sm">
					<div className="card-header">
						<h4>Open Cases by Priority</h4>
					</div>
					<div className="chart-container">
						<PieChart categData={openByPriorityData} categOptions={openByPriorityOptions} />
					</div>
				</div>
				<div className="chart-card shadow-sm">
					<div className="card-header">
						<h4>SLA Violation by Agent</h4>
					</div>
					<div className="chart-container">
						<BarChart barData={SLAViolationData} barOptions={SLAViolationOptions} />
					</div>
				</div>
				<div className="chart-card shadow-sm">
					<div className="card-header">
						<h4>SLA Violated Cases</h4>
					</div>
					<div className="chart-container">
						<StackedChart stackedChartData={SLAViolatedCasesData} stackedChartOptions={SLAViolatedCasesOptions} />
					</div>
				</div>
				<div className="chart-card shadow-sm">
					<div className="card-header">
						<h4>Completed Cases in last 30 days</h4>
					</div>
					<div className="chart-container">
						<BarChart barData={completedData} barOptions={completedCasesOptions} />
					</div>
				</div>
				
			</div>
		</div>
	);
};

export default Dashboard;
