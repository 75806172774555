import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "toast",
  showToast: false,
  toastMessage: "",
  toastTimer: 0,
};

const toastSlice = createSlice({
  name: "toastSlice",
  initialState,
  reducers: {
    setToastMessage(state, action) {
      state.toastMessage = action.payload;
    },
    setShowToast(state, action) {
      state.showToast = action.payload;
    },
    setToastTimer(state, action) {
      state.toastTimer = action.payload;
    },
  },
});

export const { setShowToast, setToastMessage, setToastTimer } =
  toastSlice.actions;
export default toastSlice;
