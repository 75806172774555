import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

const FAQs = () => {
  return (
    <div id="faqs">
      <Outlet />
    </div>
  );
};

export default FAQs;
