export const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-ZA", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const formatStatus = (inputString) => {
  const parts = inputString.split("_");
  const wordToFormat = parts.length > 1 ? parts[parts.length - 1] : inputString;
  const formattedWord =
    wordToFormat.charAt(0).toUpperCase() + wordToFormat.slice(1).toLowerCase();

  return formattedWord;
};
