export const getFileData = (whatsAppId, typeOfFile, link, caption) => {
  let payload = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: whatsAppId,
    type: typeOfFile,
  };

  payload[`${typeOfFile}`] = {
    link: link,
    caption: caption,
  };

  return payload;
};
