import React, { useState, useEffect } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { updateUserByUserPrincipalName } from "../../../slices/api/usersApi";
import Constants from "../../../constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../../slices/ToastSlice";
import { logoutAsync } from "../../../slices/AuthSlice";
import { sendEmailApi } from "../../../services/commonService";
import { useNavigate } from "react-router-dom";
import { getDepartmentById } from "../../../slices/api/departmentsApi";

const UserDetails = ({ user, users, departmentName, onBack }) => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(!!user.isSuperAdmin);
  const [isAdmin, setIsAdmin] = useState(!!user.isAdmin);
  const [isSpecialist, setIsSpecialist] = useState(!!user.isSpecialist);
  const [originalRoles, setOriginalRoles] = useState({
    isSuperAdmin: !!user.isSuperAdmin,
    isAdmin: !!user.isAdmin,
    isSpecialist: !!user.isSpecialist,
  });
  const authUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setOriginalRoles({
      isSuperAdmin: !!user.isSuperAdmin,
      isAdmin: !!user.isAdmin,
      isSpecialist: !!user.isSpecialist,
    });
  }, [user]);

  const getRoleDescription = (roleName) => {
    switch (roleName) {
      case "Super Admin":
        return "This role grants the overall system administrative actions.";
      case "Department Admin":
        return "This role grants the user administrative actions in their departments.";
      case "Support First Line Agent":
        return "This role enables a user to attend incoming case reports, via calls and chat, and has the ability to assign cases within their department.";
      default:
        return "";
    }
  };

  const getAdminDetails = () => {
    const adminDetails = users.find(
      (user) => user.mail == authUser.user.userInfo.mail
    );

    return adminDetails ? authUser.user.userInfo.displayName : "Name Not found";
  };

  const handleSubmit = async () => {
    const hasUpdates =
      isSuperAdmin !== originalRoles.isSuperAdmin ||
      isAdmin !== originalRoles.isAdmin ||
      isSpecialist !== originalRoles.isSpecialist;

    if (hasUpdates) {
      try {
        const payload = {
          isSuperAdmin,
          isAdmin,
          isSpecialist,
          dateUpdated: new Date().toString(),
          auditTrail: {
            auditType: Constants.auditType.USER_TYPE_UPDATE,
            actionPerson: authUser.user.userInfo.mail,
            userTypeData: {
              from: originalRoles,
              to: {
                isSuperAdmin,
                isAdmin,
                isSpecialist,
              },
            },
            dateCreated: new Date().toString(),
          },
        };

        await updateUserByUserPrincipalName(user.mail, payload);

        // Sending emails about role changes
        const sendRoleUpdateEmail = async (role, roleName) => {
          let actionType = role ? "Added" : "Removed";
          let templateDataForUser = {
            userName: "User",
            actionType,
            userRole: roleName,
            roleDescription: getRoleDescription(roleName),
            adminUpdated: getAdminDetails(),
            serviceDeskLink: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
          };

          const emailPayloadForUser = {
            recipientEmailAddresses: [user.mail],
            templateData: templateDataForUser,
            sourceEmail: Constants.sourceEmailList.NO_REPLY,
            templateName: "role_updated",
          };

          if (emailPayloadForUser.recipientEmailAddresses.length > 0) {
            try {
              await sendEmailApi(emailPayloadForUser);
            } catch (e) {
              console.log("Status - 500", e);
            }
          }
        };

        if (isSuperAdmin !== originalRoles.isSuperAdmin) {
          await sendRoleUpdateEmail(isSuperAdmin, "Super Admin");
        }

        if (isAdmin !== originalRoles.isAdmin) {
          await sendRoleUpdateEmail(isAdmin, "Department Admin");
        }

        if (isSpecialist !== originalRoles.isSpecialist) {
          await sendRoleUpdateEmail(isSpecialist, "Support First Line Agent");
        }

        dispatch(setToastMessage("Successfully updated user type."));
        dispatch(setToastTimer(3000));
        dispatch(setShowToast(true));

        if (authUser.user.userInfo.mail === user.mail) {
          dispatch(logoutAsync());
          navigate("/");
        } else {
          onBack();
        }
      } catch (error) {
        console.log(error);
        dispatch(
          setToastMessage("Error occurred while trying to update user type.")
        );
        dispatch(setToastTimer(3000));
        dispatch(setShowToast(true));
      }
    } else {
      dispatch(setToastMessage("No update made."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  const handleSuperAdminChange = (event) => {
    setIsSuperAdmin(event.target.checked);
  };

  const handleAdminChange = (event) => {
    setIsAdmin(event.target.checked);
  };

  const handleSpecialistChange = (event) => {
    setIsSpecialist(event.target.checked);
  };

  const handleCancel = () => {
    setIsSuperAdmin(originalRoles.isSuperAdmin);
    setIsAdmin(originalRoles.isAdmin);
    setIsSpecialist(originalRoles.isSpecialist);
  };

  return (
    <div>
      <button type="button" className="back-btn" onClick={onBack}>
        <ChevronLeftIcon /> Back
      </button>

      <div className="user-details">
        <h2>
          {user.givenName} {user.surname}
        </h2>
        <table>
          <tbody>
            <tr>
              <td>Email:</td>
              <th>{user.mail}</th>
            </tr>
            <tr>
              <td>Phone:</td>
              <th>{user.mobilePhone}</th>
            </tr>
            <tr>
              <td>Location:</td>
              <th>{user.officeLocation}</th>
            </tr>
            <tr>
              <td>Department:</td>
              <th> {departmentName}</th>
            </tr>
            <tr>
              <td>City:</td>
              <th>{user.city}</th>
            </tr>
          </tbody>
        </table>

        <div className="checkbox-container">
          <div>
            <input
              type="checkbox"
              id="super_admin_chk"
              checked={isSuperAdmin}
              onChange={handleSuperAdminChange}
            />
            <label htmlFor="super_admin_chk">Is Super Admin</label>
          </div>
          <div>
            <input
              type="checkbox"
              checked={isAdmin}
              id="admin_chk"
              onChange={handleAdminChange}
            />
            <label htmlFor="admin_chk">Is Admin</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="technician_chk"
              checked={isSpecialist}
              onChange={handleSpecialistChange}
            />
            <label htmlFor="technician_chk">Is Technician/Specialist </label>
          </div>
        </div>

        {(originalRoles.isAdmin !== isAdmin ||
          originalRoles.isSuperAdmin !== isSuperAdmin ||
          originalRoles.isSpecialist !== isSpecialist) && (
          <div className="action-btns-container">
            <button type="submit" className="main-btn" onClick={handleSubmit}>
              Save Changes
            </button>
            <button
              type="button"
              className="no-border-btn"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
