import React from 'react';
import { Chart } from "react-google-charts"

const PieChart = ({categData, categOptions}) => {

    return (
        <>
            <Chart
                chartType='PieChart'
                data={categData}
                options={categOptions}
                width="100%"
                height="250px"
            />
        </>
    )
}

export default PieChart