import React from 'react'

const DashboardTable = () => {
  return (
    <div className='dashboard-tbl'>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>OPEN</th>
                    <th>ON HOLD</th>
                    <th>OVERDUE</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Service Desk Portal</td>
                    <td>23</td>
                    <td>8</td>
                    <td>4</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>8</td>
                    <td>2</td>
                    <td>3</td>
                </tr>
                <tr>
                    <td>WhatsApp</td>
                    <td>16</td>
                    <td>7</td>
                    <td>3</td>
                </tr>
                <tr>
                    <td>Web Chat</td>
                    <td>14</td>
                    <td>3</td>
                    <td>6</td>
                </tr>

                <tr className='totals'>
                    <th>Total</th>
                    <th>61</th>
                    <th>20</th>
                    <th>13</th>
                </tr>
            </tbody>
        </table>
    </div>
  )
}

export default DashboardTable;