import React, { useEffect, useState } from "react";
import CategoryCard from "./CategoryCard";
import SubCategoryList from "./SubCategoryList";
const CategoryList = ({ parent, setParent, departmentsList }) => {
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const getSubCategories = (category) => {
    return departmentsList.filter(
      (categoryItem) => categoryItem.parentCategory === category.id
    );
  };

  const handleCategoryClick = (category) => {
    setParent(category.name);
    setSelectedCategory(category);
    const subCategories = getSubCategories(category);
    setSubCategories(subCategories);
  };

  useEffect(() => {
    if (selectedCategory) {
      setParent(selectedCategory.name);
      setSelectedCategory(selectedCategory);
      const subCategories = getSubCategories(selectedCategory);
      setSubCategories(subCategories);
    }
  }, [departmentsList]);

  return (
    <>
      {parent === "main" ? (
        <div className="departments-list">
          {departmentsList.map((category) => {
            if (category.parentCategory === "main") {
              return (
                <CategoryCard
                  key={category.id}
                  category={category}
                  departmentsList={departmentsList}
                  onClickCategory={handleCategoryClick}
                />
              );
            }
            return null;
          })}
        </div>
      ) : (
        <SubCategoryList
          subCategories={subCategories}
          selectedCategory={selectedCategory}
        />
      )}
    </>
  );
};

export default CategoryList;
