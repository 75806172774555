import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  deleteDoc,
  arrayUnion,
  getFirestore,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setChats } from "../ChatSlice";
import Constants from "../../constants/Constants";
import axios from "axios";
const WHATSAPP_END_POINT = Constants.whatsapp.WHATSAPP_END_POINT;
const headers = {
  Authorization: `Bearer ${Constants.whatsapp.WHATSAPP_TOKEN}`,
  "Content-Type": "application/json",
};

export const subscribeToChats = (dispatch, userDepartment, isSuperAdmin) => {
  const chatsRef = collection(db, "chats");
  let q = null;

  if (isSuperAdmin) {
    q = query(
      chatsRef,
      where("status", "not-in", [
        Constants.chatStages.ARCHIVED,
        Constants.chatStages.BLOCKED,
      ])
    );
  } else {
    q = query(
      chatsRef,
      where("status", "not-in", [
        Constants.chatStages.ARCHIVED,
        Constants.chatStages.BLOCKED,
      ]),
      where("category", "==", userDepartment)
    );
  }

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const chats = snapshot.docs.map((doc) => {
      let chatData = doc.data();
      // Convert date strings to Date objects
      chatData.dateCreated = new Date(chatData.dateCreated).getTime();
      chatData.dateUpdated = new Date(chatData.dateUpdated).getTime();

      return { id: doc.id, ...chatData };
    });

    const updatedChats = chats.filter(
      (chat) => chat.status !== Constants.chatStages.BLOCKED
    );

    // Sort by dateCreated
    updatedChats.sort((a, b) => {
      return a.dateCreated - b.dateCreated;
    });

    const sortedChats = updatedChats.map((chat) => {
      // Convert Date objects back to strings if necessary
      chat.dateCreated = chat.dateCreated.toString();
      chat.dateUpdated = chat.dateUpdated.toString();

      return { ...chat };
    });

    dispatch(setChats(sortedChats));
  });

  return unsubscribe;
};

export const updateChat = async (chatId, chatData) => {
  try {
    const db = getFirestore();
    const chatRef = doc(db, "chats", chatId);

    const updateData = { ...chatData };

    if (chatData.hasOwnProperty("message")) {
      updateData.messages = arrayUnion(chatData.message);
      delete updateData.message;
    }

    await updateDoc(chatRef, updateData);
  } catch (error) {
    console.error("Error updating chat:", error);
    throw new Error("Error occurred while updating chat");
  }
};

export const updateChatStatusApi = async (chatId, status, reason) => {
  try {
    const db = getFirestore();
    const chatRef = doc(db, "chats", chatId);

    let updateData = {
      status: status,
      dateUpdate: new Date().toDateString(),
      messages: arrayUnion(reason),
    };

    updateDoc(chatRef, updateData);
  } catch (error) {
    console.error("Error updating chat status:", error);
    throw new Error("Error occured, 506");
  }
};

export const addChat = async (message) => {
  try {
    const chatRef = collection(db, "chats");
    const chatData = {
      message,
      dateCreated: new Date(),
      dateUpdated: new Date(),
    };

    await addDoc(chatRef, chatData);

    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const sendWhatsAppMessage = (whatsAppId, messageBody) => {
  const payload = {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: whatsAppId,
    type: "text",
    text: {
      preview_url: false,
      body: messageBody,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(WHATSAPP_END_POINT, payload, {
        headers: headers,
      })
      .then((res) => {
        resolve("ok");
      })
      .catch((error) => {
        console.log("Error occured: ", error);
        reject(error);
      });
  });
};

export const sendInteractiveWhatsAppMessage = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(WHATSAPP_END_POINT, payload, {
        headers: headers,
      })
      .then((res) => {
        resolve("ok");
      })
      .catch((error) => {
        console.log("Error occured: ", error);
        reject(error);
      });
  });
};
