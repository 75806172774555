import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "dashboard",
  isDepartmentDialogShown: false,
  isAddUserDialogShown: false,
};

const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    changeTab(state, action) {
      state.tab = action.payload;
    },
    toggleUserDialog(state) {
      state.isAddUserDialogShown = !state.isAddUserDialogShown;
    },
    toogleDepartmentDialog(state) {
      state.isDepartmentDialogShown = !state.isDepartmentDialogShown;
    },
  },
});

export const globalActions = globalSlice.actions;
export default globalSlice;
