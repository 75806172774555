import {
  collection,
  query,
  where,
  onSnapshot,
  runTransaction,
  getDoc,
  addDoc,
  doc,
  deleteDoc,
  arrayUnion,
  getFirestore,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setConfigs } from "../ConfigSlice";

export const updateConfigsImpacts = async (updatedImpacts) => {
  try {
    const configsRef = collection(db, "configs");
    const docRef = doc(configsRef, "configs");

    await updateDoc(docRef, {
      impacts: updatedImpacts,
      dateUpdatedImpacts: new Date().toString(),
    });
    console.log("Updated impacts");
  } catch (error) {
    console.error("Error updating impact: ", error);
    throw error;
  }
};

export const subscribeToConfigs = async (dispatch) => {
  const configsCollectionRef = collection(db, "configs");
  const q = query(configsCollectionRef);
  let configs;

  const unsubscribe = onSnapshot(q, (snapshot) => {
    if (snapshot.docs && snapshot.docs.length > 0) {
      let configsData = snapshot.docs[0].data();

      configs = {
        caseStatuses: configsData.caseStatuses,
        impacts: configsData.impacts,
        priorities: configsData.priorities,
      };
    } else {
      configs = {
        caseStatuses: [],
        impacts: [],
        priorities: [],
      };
    }
    dispatch(setConfigs(configs));
  });

  return unsubscribe;
};
