import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import MyEditor from "../Editor";
import DragAndDropBox from "../DragAndDropBox";
import {
  createCase as createCaseApi,
  subscribeToCases,
} from "../../slices/api/casesApi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import StorageService from "../../services/storageService";
import Constants from "../../constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfigsAsync } from "../../slices/ConfigSlice";
import { fetchUsersAsync } from "../../slices/UserSlice";
import { subscribeToDepartments } from "../../slices/api/departmentsApi";
import { sendEmailApi } from "../../services/commonService";
import SuccessDialog from "../SuccessDialog";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../slices/ToastSlice";
import { setActiveCase } from "../../slices/CasesSlice";

const CreateCase = () => {
  const dispatch = useDispatch();
  const configsState = useSelector((state) => state.configs);
  const requestersState = useSelector((state) => state.requester);
  const usersState = useSelector((state) => state.user);
  const authUser = useSelector((state) => state.auth);
  const departmentsState = useSelector((state) => state.departments);

  const formRef = useRef(null);
  const storageService = new StorageService();
  const [files, setFiles] = useState([]);
  const [caseSummary, setCaseSummary] = useState("");
  const [caseDescription, setCaseDescription] = useState("");
  const [caseStatuses, setCaseStatuses] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [requesters, setRequesters] = useState([]);
  const [selectedRequester, setSelectedRequester] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedImpact, setSelectedImpact] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [selectedDeparment, setSelectedDepartment] = useState();
  const [categories, setCategories] = useState([]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    fetchConfigsAsync(dispatch);
    fetchUsersAsync(dispatch);

    if (authUser !== null && authUser !== undefined) {
      const isSuperAdmin = authUser.user.userInfo.isSuperAdmin ? true : false;

      subscribeToDepartments(
        dispatch,
        authUser.user.userInfo.isAdmin,
        authUser.user.userInfo.department
      );

      subscribeToCases(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );
    }
  }, [dispatch, authUser]);

  useEffect(() => {
    if (configsState && configsState.caseStatuses.length > 0) {
      setCaseStatuses(configsState.caseStatuses);
      if (!selectedStatus) {
        setSelectedStatus(configsState.caseStatuses[0]);
      }
    }

    if (configsState && configsState.priorities.length > 0) {
      setPriorities(configsState.priorities);
    }

    if (configsState && configsState.impacts.length > 0) {
      setImpacts(configsState.impacts);
    }

    if (departmentsState && departmentsState.departmentsList) {
      const filteredDepartments = departmentsState.departmentsList.filter(
        (category) => category.parentCategory == "main" && category.casesEnabled
      );
      setDepartments(filteredDepartments);
    }

    if (usersState && usersState.users.length > 0) {
      setRequesters(usersState.users);
    }
  }, [configsState, requestersState, usersState, departmentsState]);

  const handleRequesterChange = (event, selected) => {
    setSelectedRequester(selected);
  };

  const handlePriorityChange = (event, selected) => {
    setSelectedPriority(selected);
  };

  const handleImpactChange = (event, selected) => {
    setSelectedImpact(selected);
  };

  const handleStatusChange = (event, selected) => {
    setSelectedStatus(selected);
  };

  const handleAssigneeChange = (_, newAssignees) => {
    setSelectedAssignees(newAssignees);
  };

  const handleCategoryChange = (_, newCategory) => {
    setSelectedCategory(newCategory);
  };

  const handleDepartmentChange = (event, selected) => {
    setSelectedDepartment(selected.id);
  };

  const getDepartmentAdminsEmails = (departmentId) => {
    const departmentDetails =
      departmentsState.departmentsList[
        departmentsState.departmentsList.findIndex(
          (department) => department.id == departmentId
        )
      ];

    return Object.keys(departmentDetails.admins);
  };

  useEffect(() => {
    if (selectedDeparment) {
      setAssignees([]);
      setSelectedAssignees([]);
      const departmentUsers = usersState.users.filter(
        (user) =>
          user.department.replaceAll(" ", "_").toLowerCase() ==
          selectedDeparment.replaceAll(" ", "_").toLowerCase()
      );
      setAssignees(departmentUsers);

      if (departmentsState && departmentsState.departmentsList) {
        const filteredSubCategories = departmentsState.departmentsList.filter(
          (category) =>
            category.parentCategory ==
            selectedDeparment.replaceAll(" ", "_").toLowerCase()
        );

        setCategories(filteredSubCategories);
      }
    }
  }, [selectedDeparment]);

  const handleSubmitCase = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const newCaseData = {
      caseSummary: document.getElementById("case_summary").value,
      reportedBy: authUser.user.userInfo.mail,
      caseDescription: caseDescription,
      department: selectedDeparment,
      category: selectedCategory ? selectedCategory.id : "",
      assignedTo: selectedAssignees.map((data) => data.mail),
      status: selectedStatus.value,
      caseRequester: selectedRequester.mail,
      priority: selectedPriority.value,
      impact: selectedImpact.value,
      dateCreated: new Date().toString(),
      comments: [],
      slaEscalated: false,
    };

    newCaseData.attachments = [];

    for (var i = 0; i < files.length; i++) {
      try {
        const uploadFileResult = await storageService.uploadFile(
          files[i],
          Constants.typeOfService.CASES
        );

        newCaseData.attachments.push({
          attachmentUrl: uploadFileResult,
          messageId: "main",
          dateUploaded: new Date().toString(),
        });
      } catch (error) {
        setIsSubmitting(false);
        alert("Error occurred while uploading");
        console.log(error);
        return;
      }
    }

    try {
      const createCaseResults = await createCaseApi(newCaseData);

      //start sending to reporter
      const templateDataForReporter = {
        caseNumber: createCaseResults.caseNumber,
        caseLink: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/cases/single_case/${createCaseResults.id}`,
        department: selectedDeparment,
        caseStatus: selectedStatus.value,
        caseSummary: newCaseData.caseSummary,
        userName: "User",
      };

      const emailPayloadForReporter = {
        recipientEmailAddresses: [newCaseData.caseRequester],
        templateData: templateDataForReporter,
        sourceEmail: Constants.sourceEmailList.NO_REPLY,
        templateName: "case_created_for_user_update24",
      };

      try {
        await sendEmailApi(emailPayloadForReporter);
      } catch (e) {
        console.log("Status - 500", e);
      }
      //done sending to reporter

      //start sending to assignees
      let templateDataForAssignees = {
        assigneeName: "User",
        caseNumber: createCaseResults.caseNumber,
        caseSummary: newCaseData.caseSummary,
        impactType: selectedImpact.value,
        caseLink: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/cases/single_case/${createCaseResults.id}`,
      };

      const emailPayloadForAssignees = {
        recipientEmailAddresses: newCaseData.assignedTo,
        templateData: templateDataForAssignees,
        sourceEmail: Constants.sourceEmailList.NO_REPLY,
        templateName: "user_assigned_on_case_v1",
      };

      if (emailPayloadForAssignees.recipientEmailAddresses.length > 0) {
        try {
          await sendEmailApi(emailPayloadForAssignees);
        } catch (e) {
          console.log("Status - 500", e);
        }
      }
      //done sending to assignees

      dispatch(setToastMessage("Successfuly created the case."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));

      const timeoutId = setTimeout(() => {
        // navigate("/cases/single_case/" + createCaseResults.id); //idealy should come here..
        setIsSubmitting(false);
        navigate("/cases");
      }, 3400);
      return () => clearTimeout(timeoutId);
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
      dispatch(setToastMessage("Failed to create case, please try again."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(!showSuccessDialog);
  };

  return (
    <>
      <div id="create_new_case">
        <div className="header-part">
          <button
            type="button"
            className="back-btn"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon /> Back
          </button>
          <h2>New Case</h2>
        </div>

        <div className="my-form-container">
          <form ref={formRef} id="newCaseForm" method="post">
            <div className="inputs-container row gy-3">
              <div className="col-3">
                <label htmlFor="" className="required-field">
                  Requester Name
                </label>
                <Autocomplete
                  value={selectedRequester}
                  onChange={handleRequesterChange}
                  options={requesters}
                  getOptionLabel={(user) => `${user.givenName} ${user.surname}`}
                  isOptionEqualToValue={(option, value) =>
                    option.mail === value.mail
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Select Requester --"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </div>
              <div className="col-3">
                <label htmlFor="" className="required-field">
                  Priority
                </label>
                <Autocomplete
                  value={selectedPriority}
                  onChange={handlePriorityChange}
                  options={priorities}
                  getOptionLabel={(priority) => priority.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Select Priority --"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </div>
              <div className="col-3">
                <label htmlFor="" className="required-field">
                  Impact
                </label>
                <Autocomplete
                  value={selectedImpact}
                  onChange={handleImpactChange}
                  options={impacts}
                  getOptionLabel={(impact) => impact.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Select Impact --"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </div>

              <div className="col-3">
                <label htmlFor="">Status</label>
                <Autocomplete
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  options={caseStatuses}
                  getOptionLabel={(caseStatus) => caseStatus.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="-- Select Status --"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <h4>Assignee Details</h4>
            <div className="inputs-container row">
              <div className="col-12 row gb-3">
                <div className="col-4">
                  <label htmlFor="" className="required-field">
                    Department
                  </label>
                  <Autocomplete
                    value={selectedDeparment}
                    onChange={handleDepartmentChange}
                    options={departments}
                    getOptionLabel={(department) => department.name}
                    // isOptionEqualToValue={(option, value) =>
                    //   option.name == value.id
                    // }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="-- Select Category --"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="">Category</label>
                  <Autocomplete
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={categories}
                    getOptionLabel={(category) => category.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="-- Select Category --"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="">Assignee</label>
                  <Autocomplete
                    multiple
                    limitTags={5}
                    onChange={handleAssigneeChange}
                    options={assignees}
                    getOptionLabel={(assignee) =>
                      assignee.givenName + " " + assignee.surname
                    }
                    getOptionSelected={(option, value) =>
                      option.mail === value.mail
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="-- Select Assignees --"
                        variant="outlined"
                      />
                    )}
                    value={selectedAssignees}
                    isOptionEqualToValue={(option, value) =>
                      option.mail === value.mail
                    }
                  />
                </div>
              </div>
            </div>
            <div className="inputs-container">
              <h4>Case Details</h4>
              <div>
                <label htmlFor="case_summary" className="required-field">
                  Case Summary
                </label>
                <input
                  type="text"
                  name="case_summary"
                  id="case_summary"
                  className="case-summary"
                  required
                  maxLength={100}
                  placeholder="Case Summary"
                  value={caseSummary}
                  onChange={(e) => setCaseSummary(e.target.value)}
                />
                <label htmlFor="">Case Description</label>
                <MyEditor
                  comment={caseDescription}
                  setComment={setCaseDescription}
                />
              </div>
            </div>
            <div className="inputs-container">
              <h4>Attachments</h4>
              <div>
                <DragAndDropBox
                  files={files}
                  setFiles={setFiles}
                  isEditable={true}
                />
              </div>
            </div>

            <div className="btn-container">
              <button type="button" className="cancel" disabled={isSubmitting}>
                Cancel
              </button>
              <button
                type="submit"
                className="main-btn"
                disabled={isSubmitting}
                onClick={handleSubmitCase}
              >
                {isSubmitting ? "Creating..." : "Create Case"}
              </button>
            </div>
          </form>
        </div>
        {showSuccessDialog && (
          <SuccessDialog
            message={"You case was successfully logged!"}
            onClose={handleCloseSuccessDialog}
          />
        )}
      </div>
    </>
  );
};

export default CreateCase;
